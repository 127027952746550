import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { EditModuleEnum } from 'types/practiceFront';
import { Tabs } from 'antd';
import About from '../About';
import Specialties from '../Specialties';
// import Insurance from '../Insurance';
import Photos from '../Photos';
import { UniprofileFormData } from 'types/provider';
// import { INSURANCE_TYPE_CASH } from 'constants/common';
import type { TabsProps } from 'antd';
import { ISaveGenericInfoReqInput } from 'types/operation';

interface IProps {
    id: number;
    setSpecialtiesIsEdting?: (status: boolean) => void;
    specialtiesIsEdting?: boolean;
    providerData: UniprofileFormData;
    onSubmit?: (formValue, field: EditModuleEnum, callback?: () => void) => void;
    refetch: () => void;
    onTabChange: (key: string) => void;
    onSaveSuccess?: (value: string[]) => void;
    genericDetails?: ISaveGenericInfoReqInput;
}

const UpdateUniprofile = ({
    genericDetails,
    id,
    setSpecialtiesIsEdting,
    specialtiesIsEdting,
    providerData,
    onSubmit,
    refetch,
    onTabChange,
    onSaveSuccess,
}: IProps) => {
    const [tabItems, setTabItems] = useState<TabsProps['items']>([]);

    const handleTabClick = useCallback(() => {}, []);

    useEffect(() => {
        const tabs = [
            {
                key: 'About me',
                label: 'About',
                children: <About genericDetails={genericDetails} providerData={providerData} onSubmit={onSubmit} refetch={refetch} />,
            },
        ];

        if (providerData?.practice?.specialtyList?.length > 0 || providerData?.practice?.conditionTreatedList?.length > 0) {
            tabs.push({
                key: 'Specialties',
                label: 'Specialties',
                children: <Specialties
                    genericDetails={genericDetails}
                    onSaveSuccess={onSaveSuccess}
                    id={id}
                    providerId={providerData.id}
                    specialtiesIsEdting={specialtiesIsEdting}
                    setSpecialtiesIsEdting={setSpecialtiesIsEdting}
                    providerData={providerData}
                    refetch={refetch}
                    onSubmit={onSubmit}
                />,
            });
        }

        // if (providerData?.insurance?.supportPayType !== INSURANCE_TYPE_CASH) {
        //     tabs.push({
        //         key: tabs.length.toString(),
        //         label: 'Insurances',
        //         children: <Insurance providerData={providerData} />,
        //     });
        // }
        if (providerData?.profile?.additionalPhotoList && providerData?.profile?.additionalPhotoList.length > 0) {
            tabs.push({
                key: 'photos',
                label: 'Photos',
                children: <Photos providerData={providerData} />,
            });
        }

        setTabItems(tabs);
    }, [genericDetails, id, onSaveSuccess, onSubmit, providerData, refetch, setSpecialtiesIsEdting, specialtiesIsEdting]);

    return (
        <div className={s.wrap}>
            <Tabs
                tabBarStyle={{ position: 'sticky', top: 0, backgroundColor: '#F9FAFB', zIndex: 2, marginBottom: '24px' }}
                onTabClick={handleTabClick}
                onChange={(key: string) => {
                    let v = key;
                    if (key === 'photos') {
                        v = 'About me';
                    }
                    onTabChange(v);
                }}
                defaultActiveKey="About me"
                // activeKey={tabKey}
                items={tabItems}
                centered
            />
        </div>
    );
};

export default UpdateUniprofile;
