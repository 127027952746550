import React, { useCallback, useMemo } from 'react';
import s from './s.module.less';
import { Button } from 'antd';
import { ButtonType, ButtonHTMLType } from 'antd/es/button';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';

interface IButtonProps {
    type: ButtonType;
    htmlType?: ButtonHTMLType;
    form?: string;
    loading?: boolean;
    disabled?: boolean;
    onClick?: () => void
}

interface IProps {
    showSkipButton?: boolean;
    showBack?: boolean;
    loading: boolean;
    nextDisabled?: boolean;
    submitText?: string;
    onBackClick?: () => void;
    onNextClick?: () => void;
    onSkipClick?: () => void;
}

const Footer = ({
    showSkipButton,
    showBack = true,
    loading,
    nextDisabled,
    submitText,
    onBackClick,
    onNextClick,
    onSkipClick,
}: IProps) => {
    const navigate = useNavigate();
    const handleExit = useCallback(() => {
        navigate(`${PATH.DASHBOARD}/${PATH.REPUTATION_MANAGEMENT}`);
    }, [navigate]);

    const renderNextButton = useMemo(() => {
        const buttonText = submitText || 'Next';
        const buttonProps: IButtonProps = {
            type: 'primary',
            onClick: onNextClick,
            disabled: nextDisabled,
            loading,
        };

        return <Button {...buttonProps}>{buttonText}</Button>;
    }, [onNextClick, nextDisabled, submitText, loading]);

    return (
        <div className={s.footer}>
            <div className={s.footerInner}>
                <div className={s.leftBtns}>
                    <Button onClick={handleExit}>Exit</Button>
                    { showBack && <span className={s.back} onClick={onBackClick}>Back</span> }
                </div>
                <div className={s.rightBtns}>
                    {showSkipButton && <Button className={s.skipBtn} onClick={() => onSkipClick?.()}>Do it later</Button>}
                    {renderNextButton}
                </div>
            </div>
        </div>
    );
};

export default Footer;
