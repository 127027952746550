import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { Button, Modal, Spin, Tooltip, message } from 'antd';
import { getAiTags } from 'api/ai';
import { EditModuleEnum } from 'types/practiceFront';

interface IAIGeneratedTagsProps {
    value?: string[];
    field?: EditModuleEnum;
    providerId?: number;
    conditionId?: string;
    onSubmit?: (tags: string[], field: EditModuleEnum, callback?: () => void) => void;
}

const AIGeneratedTags = ({ value, providerId, conditionId, onSubmit, field = EditModuleEnum.GENERIC_TAGS }: IAIGeneratedTagsProps) => {
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [isGetTagsLoading, setIsGetTagsLoading] = useState(false);
    const [tagsData, setTagsData] = useState<string[]>([]);
    const [pendingSelectedTags, setPendingSelectedTags] = useState<string[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const initValues = useCallback(() => {
        const tagsValue = value?.filter((tag) => !!tag) || [];
        setPendingSelectedTags(tagsValue);
        setSelectedTags(tagsValue);
    }, [value]);

    useEffect(() => {
        initValues();
    }, [initValues]);

    const handleGetTags = async () => {
        if (!providerId) {
            return;
        }
        setIsGetTagsLoading(true);
        try {
            const res = await getAiTags(providerId, conditionId);
            if (res && !res.error) {
                // 从res.data.data中删除pendingSelectedTags
                const newTagsData = res.data.data.filter((tag: string) => !pendingSelectedTags.includes(tag));
                setTagsData(newTagsData);
            } else {
                message.error(res?.error);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsGetTagsLoading(false);
        }
    };

    const handleTagSelect = (tag: string) => {
        // 选中了，则从tagsData中删除， selectedTags中所有tag保持唯一不重复
        const newTagsData = tagsData.filter((t) => t !== tag);
        setTagsData(newTagsData);
        const newSelectedTags = [...pendingSelectedTags, tag];
        setPendingSelectedTags(newSelectedTags);
    };

    const handleRemoveTag = (tag: string) => {
        const newSelectedTags = pendingSelectedTags.filter((t) => t !== tag);
        setPendingSelectedTags(newSelectedTags);
        setTagsData([...tagsData, tag]);
    };

    return (
        <div className={s.wrap}>
            <Modal
                title="Edit AI-generated tags"
                open={editModalVisible}
                okText="Save changs"
                cancelText="Cancel"
                onOk={() => {
                    setSelectedTags(pendingSelectedTags);
                    onSubmit?.(pendingSelectedTags.filter((tag) => !!tag), field, () => {
                        setEditModalVisible(false);
                    });
                }}
                onCancel={() => {
                    initValues();
                    setEditModalVisible(false);
                }}
                destroyOnClose
            >
                <Spin spinning={isGetTagsLoading}>
                    <div className={s.modalWrap}>
                        <div className={`${s.label} ${s.required}`}>
                            Tags
                        </div>
                        <div className={s.selectInput}>
                            {
                                pendingSelectedTags.map((tag) => (
                                    <div
                                        className={s.selectedItem}
                                        key={tag}
                                        onClick={() => {
                                            handleRemoveTag(tag);
                                        }}
                                    >
                                        {tag}
                                    </div>
                                ))
                            }
                        </div>
                        <div className={`${s.label}`}>
                            More AI-generated tags you may want to add:
                        </div>
                        <div className={`${s.tags} ${pendingSelectedTags.length > 2 ? s.disabledList : ''}`}>
                            {
                                tagsData.map((tag) => {
                                    if (pendingSelectedTags.length > 2) {
                                        return (
                                            <Tooltip title="You can select up to 3 tags only.">
                                                <div key={tag} className={`${s.tag} ${s.disabledTag}`}>
                                                    {tag}
                                                </div>
                                            </Tooltip>
                                        );
                                    }
                                    return (
                                        <div
                                            className={`${s.tag}`}
                                            key={tag}
                                            onClick={() => {
                                                if (pendingSelectedTags.length > 2) {
                                                    return;
                                                }
                                                handleTagSelect(tag);
                                            }}
                                        >
                                            {tag}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </Spin>
            </Modal>
            <div className={s.header}>
                <div className={s.title}>
                    <div className={s.titleText}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                            <path d="M4.9942 10.2388C5.10549 9.93808 5.53088 9.93808 5.64217 10.2388L6.42936 12.3662C6.46435 12.4607 6.5389 12.5353 6.63346 12.5703L8.76081 13.3575C9.06157 13.4688 9.06157 13.8942 8.76081 14.0054L6.63346 14.7926C6.5389 14.8276 6.46435 14.9022 6.42936 14.9967L5.64217 17.1241C5.53088 17.4248 5.10549 17.4248 4.9942 17.1241L4.20701 14.9967C4.17202 14.9022 4.09746 14.8276 4.00291 14.7926L1.87556 14.0054C1.5748 13.8942 1.5748 13.4688 1.87556 13.3575L4.00291 12.5703C4.09746 12.5353 4.17202 12.4607 4.20701 12.3662L4.9942 10.2388Z" fill="url(#paint0_linear_23903_14688)" />
                            <path d="M11.2858 1.81794C11.4533 1.36522 12.0936 1.36522 12.2611 1.81794L13.446 5.02016C13.4987 5.1625 13.6109 5.27472 13.7533 5.32739L16.9555 6.51232C17.4082 6.67984 17.4082 7.32016 16.9555 7.48768L13.7533 8.67261C13.6109 8.72528 13.4987 8.8375 13.446 8.97984L12.2611 12.1821C12.0936 12.6348 11.4533 12.6348 11.2858 12.1821L10.1008 8.97984C10.0482 8.8375 9.93594 8.72528 9.7936 8.67261L6.59138 7.48768C6.13866 7.32016 6.13866 6.67984 6.59138 6.51232L9.7936 5.32739C9.93594 5.27472 10.0482 5.1625 10.1008 5.02016L11.2858 1.81794Z" fill="url(#paint1_linear_23903_14688)" />
                            <defs>
                                <linearGradient id="paint0_linear_23903_14688" x1="2.22254" y1="10.6022" x2="7.15222" y2="17.5588" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#02FF74" />
                                    <stop offset="0.359375" stopColor="#0BCA88" />
                                    <stop offset="1" stopColor="#000AF0" />
                                </linearGradient>
                                <linearGradient id="paint1_linear_23903_14688" x1="7.11368" y1="2.36493" x2="14.5342" y2="12.8364" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#02FF74" />
                                    <stop offset="0.359375" stopColor="#0BCA88" />
                                    <stop offset="1" stopColor="#000AF0" />
                                </linearGradient>
                            </defs>
                        </svg>
                        AIGenerated Tags
                    </div>
                    <Tooltip overlayClassName={s.tipCard} overlayInnerStyle={{ padding: '12px' }} color="#000000" title={<div className={s.tipText}>{'AI-generated content based on the provider\'s Uniprofile, tailored for the selected condition.'}</div>}>
                        <div className={s.questionMarkIcon} />
                    </Tooltip>
                </div>
                <div className={s.btns}>
                    <Button onClick={() => {
                        handleGetTags();
                        initValues();
                        setEditModalVisible(true);
                    }}
                    >
                        Edit
                    </Button>
                </div>
            </div>
            <div className={s.tagsWrap}>
                {
                    selectedTags && selectedTags.length > 0 ? (
                        selectedTags.map((tag) => (
                            <div className={s.tag} key={tag}>
                                {tag}
                            </div>
                        ))
                    ) : 'No tags added yet.'
                }
            </div>
        </div>
    );
};

export default AIGeneratedTags;
