import React from 'react';
import { Button, Modal } from 'antd';
import s from './s.module.less';
import SpeedImage from './speed.svg';
import { openSubscriptionModal } from 'utils/globalLayerControl';

interface IProps {
    title?: string;
    description?: string;
    buttonText?: string;
    open: boolean;
    onCancel: () => void;
    onOk: () => void;
}

const ReplyUpgradeTipModal: React.FC<IProps> = ({
    open,
    onCancel,
    onOk,
    title,
    description,
    buttonText,
}) => {
    return (
        <Modal
            open={open}
            footer={null}
            onCancel={onCancel}
            width={496}
            destroyOnClose
        >
            <div className={s.container}>
                <img src={SpeedImage} />
                <div className={s.title}>{title}</div>
                <div className={s.description}>{description}</div>
                <Button
                    type="primary"
                    className={s.button}
                    onClick={onOk}
                >{buttonText}
                </Button>
            </div>
        </Modal>
    );
};

export default ReplyUpgradeTipModal;
