import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import s from './s.module.less';

import LandingIcon from 'assets/survey/mainPagePic.svg';
import InternalIcon from 'assets/practiceFront/globIcon.svg';
import ChatIcon from 'assets/survey/chatIcon.svg';
import PeopleGroupIcon from 'assets/practiceFront/peopleGroupIcon.svg';
import RiseIcon from 'assets/practiceFront/riseIcon.svg';
import { PATH } from 'constants/path';
import { getReputationSetupStep } from 'utils/localstore';

const Intro = () => {
    const navigate = useNavigate();
    const step = getReputationSetupStep();
    let hasStared = false;
    try {
        hasStared = step && parseInt(step, 10) > 0;
    } catch (e) {
        console.error(e);
    }
    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <h1>Reputation management</h1>
                <div className={s.des}>
                    Effectively manage and enhance your online reputation by collecting and responding to patient reviews across multiple channels to improve patient satisfaction.
                </div>
            </div>
            <div className={s.content}>
                <div className={s.left}>
                    <div className={s.list}>
                        <div className={s.item}>
                            <img src={RiseIcon} />
                            <div className={s.info}>
                                <div className={s.itemTitle}>Increased credibility</div>
                                <div className={s.itemContent}>Positive online reviews and a strong overall reputation enhance the credibility of your healthcare practice.</div>
                            </div>
                        </div>
                        <div className={s.item}>
                            <img src={PeopleGroupIcon} />
                            <div className={s.info}>
                                <div className={s.itemTitle}>Enhanced patient trust and experience</div>
                                <div className={s.itemContent}>Timely responses to reviews, questions, and concerns help foster trust by showing that you value patient input and are actively engaged in improving patient satisfaction.</div>
                            </div>
                        </div>
                        <div className={s.item}>
                            <img src={ChatIcon} />
                            <div className={s.info}>
                                <div className={s.itemTitle}>Mitigate negative feedback</div>
                                <div className={s.itemContent}>Proactively managing and responding to negative feedback helps mitigate potential reputational risks.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.right}>
                    <img src={LandingIcon} />
                </div>
            </div>
            <div className={s.footer}>
                <Button
                    type="primary"
                    onClick={() => {
                        navigate(`${PATH.REPUTATION_CONFIG_PAGE}`);
                    }}
                >
                    {/* {hasStared ? 'Resume setup' : 'Get started'} */}
                    Get started
                </Button>
                {/* <Button
                    type="primary"
                    style={{ marginLeft: 20 }}
                    onClick={() => {
                        navigate(`${PATH.DASHBOARD}/${PATH.REPUTATION_DASHBORAD}`);
                    }}
                >
                    Dashboard
                </Button> */}
            </div>
        </div>
    );
};

export default Intro;
