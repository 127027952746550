import React from 'react';
import s from './s.module.less';
import { Button, Modal } from 'antd';
import Image5 from './images/5.png';
import { useNavigate } from 'react-router-dom';

interface IProps {
    setOnboardingPopupStep: (step: number) => void;
    onClosePopup: () => void;
    practiceFrontCompleted?: boolean;
}

const ExploreChannel = ({
    setOnboardingPopupStep,
    onClosePopup,
    practiceFrontCompleted,
}: IProps) => {
    const navigate = useNavigate();

    return (
        <div className={s.wrap}>
            <div className={s.playerBox}>
                <div className={s.closeIcon} onClick={onClosePopup} />
                <div className={s.player}>
                    <img src={Image5} alt="" />
                </div>
            </div>
            <div className={s.content}>
                {
                    practiceFrontCompleted ? (
                        <>
                            <div className={`${s.title} ${s.completeIcon}`}>Your Practice Front is live</div>
                            <div className={s.desc}>Your Practice Front is set up. Keep it up to date to maintain visibility across multiple channels.</div>
                            <Button className={s.stepDirBtn} onClick={() => navigate('/dashboard/customize-practice-front')}>View Practice Front</Button>
                        </>
                    ) : (
                        <>
                            <div className={s.title}>Set up your Practice Front and list on multiple channels</div>
                            <div className={s.desc}>{'Boost your visibility and attract new patients by creating a state-of-the-art personal website with Kiwi\'s cutting-edge technology and listing on 30+ medical directories with just one click.'}</div>
                            <Button className={s.stepDirBtn} onClick={() => navigate('/dashboard/practice-front')}>Set up Practice Front</Button>
                        </>
                    )
                }
            </div>
            <div className={s.footer}>
                <div className={s.step}>Step 5 of 5</div>
                <div className={s.btns}>
                    <Button style={{ width: '120px' }} onClick={() => setOnboardingPopupStep(4)}>Previous</Button>
                    <Button style={{ width: '145px' }} type="primary" onClick={() => navigate('/dashboard/channel')}>Explore channels</Button>
                </div>
            </div>
        </div>
    );
};

export default ExploreChannel;
