import React, { useEffect, useState } from 'react';
import s from './s.module.less';
import { UniprofileFormData } from 'types/provider';
import { getDraftData } from 'utils/helloklarity';
import { IDraftStorage } from 'types/helloKlarity';
import MedicationsPrescribed from '../MedicationsPrescribed';
import SpecificSpecNConditions from 'components/HelloKlarityFormComponents/SpecificSpecNConditions';
import { EditModuleEnum } from 'types/practiceFront';
import { ISaveSpecificInfoReqListInput } from 'types/operation';

interface IProps {
    id: number;
    providerId: number;
    providerData: UniprofileFormData;
    onSubmit?: (data: any, field: EditModuleEnum, callback?: () => void) => void;
    specificConditionDetail?: ISaveSpecificInfoReqListInput;
}

const SepecificSpecialties = ({
    // providerId,
    onSubmit,
    specificConditionDetail,
}: IProps) => {
    const [specialtyHighlightListData, setSpecialtyHighlightList] = useState<string[]>([]);

    useEffect(() => {
        const { specialtyHighlight } = specificConditionDetail || {};
        setSpecialtyHighlightList(specialtyHighlight || []);
    }, [setSpecialtyHighlightList, specificConditionDetail]);

    const specialtiesApproaches = {
        specialtyList: specificConditionDetail?.specialty,
        conditionTreatedList: specificConditionDetail?.conditionTreated,
    };
    return (
        <div className={s.wrap}>
            <div className={s.editItem}>
                <SpecificSpecNConditions
                    specialtyHighlightList={specialtyHighlightListData}
                    initFormValue={specialtiesApproaches}
                    onSubmit={onSubmit}
                />
            </div>
            <div className={s.editItem}>
                <MedicationsPrescribed
                    field={EditModuleEnum.SPECIFIC_MEDICATIONS_PRESCRIBED}
                    prescribableMedications={specificConditionDetail?.prescribableMedications}
                    nonPrescribableMedications={specificConditionDetail?.nonPrescribableMedications}
                    onSubmit={onSubmit}
                />
            </div>
        </div>
    );
};

export default SepecificSpecialties;
