import React, { useCallback, useState } from 'react';
import { Button } from 'antd';
import s from './s.module.less';
import Image1 from './image1.png';
import Image2 from './image2.png';
import copy from 'copy-to-clipboard';
import SuccessToast from 'components/SuccessToast';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
import { generateKiwiReviewLink } from 'utils/common';

interface IProps {
    onAddPatientsManually?: () => void;
}

const SurveyMethodSteps: React.FC<IProps> = ({ onAddPatientsManually }) => {
    const [homeInfo, loadingHome] = useProviderHomeInfo();
    const [toastVisible, setToastVisible] = useState(false);
    const handleCopy = useCallback(() => {
        copy(generateKiwiReviewLink(homeInfo?.uidPublic) || '', { format: 'text/plain' });
        setToastVisible(true);
    }, [homeInfo]);

    const handleAddPatientsManually = useCallback(() => {
        onAddPatientsManually?.();
    }, [onAddPatientsManually]);

    return (
        <div className={s.pageContainer}>
            <SuccessToast
                visible={toastVisible}
                message="Survey link copied successfully"
                onClose={() => setToastVisible(false)}
            />
            <div className={s.container}>
                <div className={s.header}>
                    <h2 className={s.stepTitle}>Step 2</h2>
                    <h1 className={s.mainTitle}>Send the survey to patients</h1>
                    <p className={s.subtitle}>There are two ways to send the survey to your patients:</p>
                </div>

                <div className={s.methodsContainer}>
                    <div className={s.method}>
                        <div className={s.imagePlaceholder}>
                            <img src={Image1} alt="method1" />
                        </div>
                        <div className={s.methodNumber}>01</div>
                        <h3 className={s.methodTitle}>Share the survey invitation link directly to patients</h3>
                        <p className={s.methodDesc}>
                            Copy the survey invitation link from either the <span className={s.highlight}>Home page</span> or the <span className={s.highlight}>&quot;Reputation Management&quot;</span> tab and send it directly to your patients via email, text, or any other convenient channel.
                        </p>
                        <Button loading={loadingHome} type="primary" className={s.actionButton} onClick={handleCopy}>
                            Copy survey invitation link
                        </Button>
                    </div>

                    <div className={s.method}>
                        <div className={s.imagePlaceholder}>
                            <img src={Image2} alt="method2" />
                        </div>
                        <div className={s.methodNumber}>02</div>
                        <h3 className={s.methodTitle}>Add or select patients in the web app</h3>
                        <p className={s.methodDesc}>
                            All patients in <span className={s.highlight}>&quot;My Prospect&quot;</span> will be automatically synchronized to <span className={s.highlight}>&quot;Reputation Management&quot;</span> where you can select them as survey recipients. You can also add additional patients by uploading a CVS file or entering patient information manually.
                        </p>
                        <Button type="primary" className={s.actionButton} onClick={handleAddPatientsManually}>
                            Add patients manually
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SurveyMethodSteps;
