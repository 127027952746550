import React from 'react';
import { Button, Spin, Dropdown } from 'antd';
import s from './s.module.less';
import dayjs from 'utils/dayjs';
import { getTimeStr } from 'utils/calendar';
import StarReview from 'components/StarReview';
import { EReviewSource, TReviewComment, TReviewItem } from 'pages/dashboard/KlarityReviewsDashboradPage/types';
import AjustText from 'components/AjustText';
import { getKlarityReviewReply } from 'api/survey';
import ReviewDisputeStore from 'store/ReviewDispute';
import { EDISPUTE_STATUS } from 'types/common';
import DisputeStatus from 'components/DisputeStatusTag';
import OriginDayjs from 'dayjs';

type Props = {
    showDispute?: boolean;
    review?: TReviewItem,
    onClickReply?: () => void,
    isShow?: boolean,
    hideRightPart?: boolean,
    hideBorder?: boolean,
};

const ReviewItem = (props: Props) => {
    const { review, onClickReply, isShow, hideRightPart, hideBorder, showDispute } = props;
    const [, setDisputeStore] = ReviewDisputeStore.useStore();
    const [showReply, setShowReply] = React.useState(false);
    const [replyComments, setReplyComments] = React.useState<TReviewComment[]>();
    const [loadingReply, setLoadingReply] = React.useState(false);
    const [textContentWidth, setTextContentWidth] = React.useState<number>(0);

    const canReply = !!review?.isNeedReplied;
    const hasReply = !!review?.isReplied;
    if (!review) {
        return null;
    }
    const verified = review.source !== EReviewSource.PROVIDER_IMPORT;
    let sourceText = '';
    if (review?.source === EReviewSource.EHR_BOOKING) {
        sourceText = 'Klarity';
    }
    if (review?.source === EReviewSource.PROVIDER_IMPORT) {
        sourceText = 'Provider Import';
    }
    if (review?.source === EReviewSource.SURVEY_LINK) {
        sourceText = 'Collected via Survey link';
    }

    const hasReviewEmail = !!review?.reviewerEmail;
    const hasReviewPhone = !!review?.reviewerPhone;

    const fetchReply = async () => {
        if (review?.comment?.id) {
            setLoadingReply(true);
            const result = await getKlarityReviewReply(review?.id);
            if (!result?.error) {
                const replys: TReviewComment[] = result?.data?.data;
                setReplyComments(replys);
            }
            setLoadingReply(false);
        }
    };

    const handleShowDisputeDetail = () => {
        setDisputeStore('currentReview', review);
        setDisputeStore('showViewModal', true);
    };

    const handleMenuClick = (e) => {
        const { key } = e;

        if (key === '1') {
            setDisputeStore('currentReview', review);
            setDisputeStore('showEditModal', true);
            setDisputeStore('showViewModal', false);
        }
    };

    React.useEffect(() => {
        if (hasReply) {
            fetchReply();
        }
    }, [hasReply]);

    const displayComment: TReviewComment | undefined = replyComments ? replyComments[0] : undefined;

    const hasTag = !!review?.tags?.trim();
    const showRate = verified || (review?.rating || 0) > 0;
    //const showRate = false

    const menuItems = [
        {
            key: '1',
            label: 'Dispute',
        },
    ];

    const buttonRender = () => {
        if (review?.disputeStatus === EDISPUTE_STATUS.NORMAL) {
            if (canReply) {
                if (!hasReply) {
                    return (
                        <Dropdown.Button
                            onClick={onClickReply}
                            className={`${canReply ? s.btn : s.btnDisabled}`}
                            // disabled={!canReply}
                            menu={{ items: menuItems, onClick: handleMenuClick }}
                        >Reply
                        </Dropdown.Button>
                    );
                } else {
                    return (
                        <Dropdown.Button
                            // onClick={onClickReply}
                            className={s.btnDisabled}
                            menu={{ items: menuItems, onClick: handleMenuClick }}
                        >Replied
                        </Dropdown.Button>
                    );
                }
            } else {
                return (
                    <Button
                        className={s.btnDisabled}
                        onClick={() => handleMenuClick({ key: '1' })}
                    >Dispute
                    </Button>
                );
            }
        } else if (hasReply) {
            return (
                <Button
                    onClick={onClickReply}
                    className={s.btnDisabled}
                    disabled
                >Replied
                </Button>
            );
        } else {
            return (
                <Button
                    onClick={onClickReply}
                    className={`${canReply ? s.btn : s.btnDisabled}`}
                    disabled={!canReply}
                >Reply
                </Button>
            );
        }
    };

    return (
        <div className={`${s.wrap} ${hideBorder ? s.hideBorder : ''}`} key={`${review.id}`}>
            <div className={s.left}>
                {!verified &&
                <>
                    <div className={s.name}>
                        Unverified patient
                        {
                            sourceText && <span className={s.source}>| {sourceText}</span>
                        }
                    </div>
                    <div className={s.contacts}>
                        {
                            hasReviewEmail &&
                            <div className={s.infoWrap}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M2 5.33398L7.2604 8.84092C7.70827 9.1395 8.29173 9.1395 8.7396 8.84092L14 5.33398M3.33333 12.6673H12.6667C13.403 12.6673 14 12.0704 14 11.334V4.66732C14 3.93094 13.403 3.33398 12.6667 3.33398H3.33333C2.59695 3.33398 2 3.93094 2 4.66732V11.334C2 12.0704 2.59695 12.6673 3.33333 12.6673Z" stroke="#9CA3AF" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className={s.info}>{review?.reviewerEmail}</span>
                            </div>
                        }
                        {
                            hasReviewPhone &&
                            <div className={s.infoWrap}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M6.15195 2.45585L5.51949 2.66667V2.66667L6.15195 2.45585ZM7.15049 5.45147L7.78295 5.24066L7.78295 5.24066L7.15049 5.45147ZM6.81618 6.25858L6.51804 5.66229L6.81618 6.25858ZM5.31134 7.011L5.0132 6.41471C4.69317 6.57473 4.55652 6.95883 4.70359 7.28501L5.31134 7.011ZM8.989 10.6887L8.71499 11.2964C9.04117 11.4435 9.42527 11.3068 9.58529 10.9868L8.989 10.6887ZM9.74142 9.18382L9.14514 8.88568L9.14514 8.88568L9.74142 9.18382ZM10.5485 8.84951L10.7593 8.21705L10.5485 8.84951ZM13.5442 9.84805L13.3333 10.4805L13.5442 9.84805ZM3.33333 1.33333C2.22876 1.33333 1.33333 2.22876 1.33333 3.33333H2.66667C2.66667 2.96514 2.96514 2.66667 3.33333 2.66667V1.33333ZM5.51949 1.33333H3.33333V2.66667H5.51949V1.33333ZM6.78441 2.24503C6.60292 1.70057 6.0934 1.33333 5.51949 1.33333V2.66667V2.66667L6.78441 2.24503ZM7.78295 5.24066L6.7844 2.24503L5.51949 2.66667L6.51804 5.66229L7.78295 5.24066ZM7.11432 6.85486C7.7078 6.55812 7.99277 5.87014 7.78295 5.24066L6.51804 5.66229V5.66229L7.11432 6.85486ZM5.60948 7.60728L7.11432 6.85486L6.51804 5.66229L5.0132 6.41471L5.60948 7.60728ZM9.26302 10.0809C7.78142 9.41291 6.58709 8.21858 5.91909 6.73698L4.70359 7.28501C5.50524 9.06305 6.93695 10.4948 8.71499 11.2964L9.26302 10.0809ZM9.14514 8.88568L8.39272 10.3905L9.58529 10.9868L10.3377 9.48196L9.14514 8.88568ZM10.7593 8.21705C10.1299 8.00723 9.44188 8.2922 9.14514 8.88568L10.3377 9.48197V9.48196L10.7593 8.21705ZM13.755 9.2156L10.7593 8.21705L10.3377 9.48196L13.3333 10.4805L13.755 9.2156ZM14.6667 10.4805C14.6667 9.9066 14.2994 9.39708 13.755 9.2156L13.3333 10.4805H13.3333H14.6667ZM14.6667 12.6667V10.4805H13.3333V12.6667H14.6667ZM12.6667 14.6667C13.7712 14.6667 14.6667 13.7712 14.6667 12.6667H13.3333C13.3333 13.0349 13.0349 13.3333 12.6667 13.3333V14.6667ZM12 14.6667H12.6667V13.3333H12V14.6667ZM1.33333 4C1.33333 9.89104 6.10896 14.6667 12 14.6667V13.3333C6.84534 13.3333 2.66667 9.15466 2.66667 4H1.33333ZM1.33333 3.33333V4H2.66667V3.33333H1.33333Z" fill="#9CA3AF" />
                                </svg>
                                <span className={s.info}>{review?.reviewerPhone}</span>
                            </div>
                        }
                    </div>
                </>
                }
                {verified &&
                    <>
                        <div className={s.name}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                                <path d="M15.8955 4.44316L9.71001 1.75086C9.63829 1.71938 9.56081 1.70313 9.48249 1.70312C9.40416 1.70313 9.32669 1.71938 9.25496 1.75086L3.06951 4.44316C2.96764 4.48735 2.88091 4.56034 2.81998 4.65317C2.75905 4.74601 2.72658 4.85462 2.72656 4.96566V9.96266C2.72656 15.0262 9.03931 17.499 9.30816 17.6016C9.37371 17.6263 9.44306 17.6396 9.51146 17.6396C9.58271 17.6396 9.65301 17.6263 9.72046 17.5997C9.98646 17.4952 16.2365 14.9739 16.2365 9.96266V4.96566C16.2375 4.73956 16.1035 4.53341 15.8955 4.44316Z" fill="#54EFA5" />
                                <path d="M16.2371 7.70121C15.8742 7.65656 15.5037 7.63281 15.1285 7.63281C10.4288 7.63281 6.57563 11.2552 6.20703 15.8598C7.72038 16.978 9.18148 17.5499 9.30783 17.5983C9.37338 17.623 9.44273 17.6363 9.51113 17.6363C9.58238 17.6363 9.65268 17.623 9.72013 17.5964C9.98613 17.4919 16.2362 14.9706 16.2362 9.95936V7.70121H16.2371Z" fill="#42E59B" />
                                <path d="M11.3668 2.47191L9.71001 1.75086C9.63829 1.71938 9.56081 1.70313 9.48249 1.70312C9.40416 1.70313 9.32669 1.71938 9.25496 1.75086L3.06951 4.44316C2.96764 4.48735 2.88091 4.56034 2.81998 4.65317C2.75905 4.74601 2.72658 4.85462 2.72656 4.96566V9.07726C6.85906 9.07726 10.3361 6.27761 11.3668 2.47191Z" fill="#5FFFA7" />
                                <path d="M8.92374 11.9836C8.84889 11.9837 8.77476 11.969 8.70563 11.9403C8.6365 11.9116 8.57373 11.8695 8.52094 11.8164L6.24094 9.53641C6.01864 9.31411 6.01864 8.95311 6.24094 8.72986C6.34782 8.62312 6.49269 8.56316 6.64374 8.56316C6.79479 8.56316 6.93967 8.62312 7.04654 8.72986L8.91804 10.6014L12.1043 7.32861C12.3238 7.10346 12.6848 7.09776 12.9099 7.31816C13.0181 7.42365 13.0799 7.56774 13.0819 7.7188C13.0838 7.86985 13.0258 8.01551 12.9204 8.12376L9.33034 11.8107C9.27767 11.8648 9.21476 11.9078 9.14528 11.9374C9.0758 11.9669 9.00114 11.9823 8.92564 11.9827C8.92659 11.9836 8.92564 11.9836 8.92374 11.9836Z" fill="white" />
                            </svg>
                            Verified patient
                            {
                                sourceText && <span className={s.source}>| {sourceText}</span>
                            }
                        </div>
                        <div className={s.contacts}>
                            {
                                hasReviewEmail &&
                                <div className={s.infoWrap}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M2 5.33398L7.2604 8.84092C7.70827 9.1395 8.29173 9.1395 8.7396 8.84092L14 5.33398M3.33333 12.6673H12.6667C13.403 12.6673 14 12.0704 14 11.334V4.66732C14 3.93094 13.403 3.33398 12.6667 3.33398H3.33333C2.59695 3.33398 2 3.93094 2 4.66732V11.334C2 12.0704 2.59695 12.6673 3.33333 12.6673Z" stroke="#9CA3AF" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <span className={s.info}>{review?.reviewerEmail}</span>
                                </div>
                            }
                            {
                                hasReviewPhone &&
                                <div className={s.infoWrap}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M6.15195 2.45585L5.51949 2.66667V2.66667L6.15195 2.45585ZM7.15049 5.45147L7.78295 5.24066L7.78295 5.24066L7.15049 5.45147ZM6.81618 6.25858L6.51804 5.66229L6.81618 6.25858ZM5.31134 7.011L5.0132 6.41471C4.69317 6.57473 4.55652 6.95883 4.70359 7.28501L5.31134 7.011ZM8.989 10.6887L8.71499 11.2964C9.04117 11.4435 9.42527 11.3068 9.58529 10.9868L8.989 10.6887ZM9.74142 9.18382L9.14514 8.88568L9.14514 8.88568L9.74142 9.18382ZM10.5485 8.84951L10.7593 8.21705L10.5485 8.84951ZM13.5442 9.84805L13.3333 10.4805L13.5442 9.84805ZM3.33333 1.33333C2.22876 1.33333 1.33333 2.22876 1.33333 3.33333H2.66667C2.66667 2.96514 2.96514 2.66667 3.33333 2.66667V1.33333ZM5.51949 1.33333H3.33333V2.66667H5.51949V1.33333ZM6.78441 2.24503C6.60292 1.70057 6.0934 1.33333 5.51949 1.33333V2.66667V2.66667L6.78441 2.24503ZM7.78295 5.24066L6.7844 2.24503L5.51949 2.66667L6.51804 5.66229L7.78295 5.24066ZM7.11432 6.85486C7.7078 6.55812 7.99277 5.87014 7.78295 5.24066L6.51804 5.66229V5.66229L7.11432 6.85486ZM5.60948 7.60728L7.11432 6.85486L6.51804 5.66229L5.0132 6.41471L5.60948 7.60728ZM9.26302 10.0809C7.78142 9.41291 6.58709 8.21858 5.91909 6.73698L4.70359 7.28501C5.50524 9.06305 6.93695 10.4948 8.71499 11.2964L9.26302 10.0809ZM9.14514 8.88568L8.39272 10.3905L9.58529 10.9868L10.3377 9.48196L9.14514 8.88568ZM10.7593 8.21705C10.1299 8.00723 9.44188 8.2922 9.14514 8.88568L10.3377 9.48197V9.48196L10.7593 8.21705ZM13.755 9.2156L10.7593 8.21705L10.3377 9.48196L13.3333 10.4805L13.755 9.2156ZM14.6667 10.4805C14.6667 9.9066 14.2994 9.39708 13.755 9.2156L13.3333 10.4805H13.3333H14.6667ZM14.6667 12.6667V10.4805H13.3333V12.6667H14.6667ZM12.6667 14.6667C13.7712 14.6667 14.6667 13.7712 14.6667 12.6667H13.3333C13.3333 13.0349 13.0349 13.3333 12.6667 13.3333V14.6667ZM12 14.6667H12.6667V13.3333H12V14.6667ZM1.33333 4C1.33333 9.89104 6.10896 14.6667 12 14.6667V13.3333C6.84534 13.3333 2.66667 9.15466 2.66667 4H1.33333ZM1.33333 3.33333V4H2.66667V3.33333H1.33333Z" fill="#9CA3AF" />
                                    </svg>
                                    <span className={s.info}>{review?.reviewerPhone}</span>
                                </div>
                            }
                        </div>
                    </>
                }
                {
                    showRate &&
                    <div className={s.scoreInfo}>
                        <StarReview width={120} editable={false} value={review.rating} />
                        <div className={s.time}>{getTimeStr(dayjs(review?.submitAt), 'MMMM D, YYYY')}</div>
                        {
                            !review.isPublic && <span className={s.private}>Private</span>
                        }
                        {
                            review.isPublic && <span className={s.public}>Public</span>
                        }
                    </div>
                }
                {
                    hasTag &&
                    <div className={s.tagList}>
                        {
                            review?.tags?.split(',')?.map((e, index) => {
                                return <div key={index} className={s.tag}>{e}</div>;
                            })
                        }
                    </div>
                }
                <div className={s.content}>
                    <AjustText
                        preLine
                        content={review?.comment?.content}
                        line={2}
                        isShow={isShow}
                    />
                </div>
                {
                    hasReply &&
                    <div className={s.replyWrap}>
                        <div className={s.header}>
                            <div className={s.label}>Response from you</div>
                            <div className={s.time}>{review?.repliedAt ? getTimeStr(dayjs(review?.repliedAt), 'MMMM D, YYYY') : ''}</div>
                            {!showReply &&
                                <div
                                    onClick={() => {
                                        setShowReply(true);
                                    }}
                                    className={s.showText}
                                >
                                    View response
                                </div>}
                        </div>
                        {
                            showReply &&
                            <Spin size="small" spinning={loadingReply}><div className={s.replyContent}>{displayComment?.content}</div></Spin>
                        }
                    </div>
                }
            </div>
            {
                !hideRightPart && (
                    <div className={s.right}>
                        {
                            review?.disputeStatus !== EDISPUTE_STATUS.NORMAL && (
                                <div className={s.disputeInfo}>
                                    <div className={s.top}>
                                        <div className={s.time}>{review?.disputeDecisionAt ? `Last updated: ${OriginDayjs(review?.disputeDecisionAt).format('MMMM DD, YYYY')}` : `Disputed on: ${OriginDayjs(review?.disputeSubmitAt).format('MMMM DD, YYYY')}`} </div>
                                    </div>
                                    <DisputeStatus status={review?.disputeStatus} />
                                    <div className={s.viewDetail} onClick={handleShowDisputeDetail}>View details</div>
                                </div>
                            )
                        }
                        {buttonRender()}
                    </div>
                )
            }
        </div>
    );
};

export default ReviewItem;
