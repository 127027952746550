import React, { useMemo } from 'react';
import s from './s.module.less';
import { Checkbox, Modal, Select, Spin, Tag } from 'antd';
import { RESOURCE_CATEGORY } from 'constants/common';
import { TResource } from 'types/common';
import { getTimeStr } from 'utils/calendar';
import commonS from 'styles/common.module.less';
import dayjs from 'dayjs';
import { providerGetKlarityResourcesUrl } from 'api/provider';

type Props = {
    data: TResource[];
}

const ResourceCenter = (props: Props) => {
    const { data } = props;
    const options = [...RESOURCE_CATEGORY];
    const allCategory = options.map(option => option.value);
    const colors = ["#92400E", "#065F46", "#1E40AF", "#3730A3"];

    const [selectedValues, setSelectedValues] = React.useState<string[]>([]);
    const [currentItem, setCurrentItem] = React.useState<TResource>();
    const [showModal, setShowModal] = React.useState<boolean>(false);

    const allOption = useMemo(() => ({ label: 'Select All', value: 'All' }), []);
    const [url, setUrl] = React.useState<string>('');


    const getUrlFromResource = async (resource: TResource) => {
        const res = await providerGetKlarityResourcesUrl(resource.privateS3Uri!);
        if (!res.error) {
            const url = res.data?.data?.singedUrl;
            setUrl(url);
        }
    };

    React.useEffect(() => {
        if (currentItem) {
            getUrlFromResource(currentItem);
        }
    }, [currentItem]);

    const handleChange = (values: string[]) => {
        if (values.includes(allOption.value)) {
            if (selectedValues.length === options.length) {
                setSelectedValues([]);
            } else {
                setSelectedValues(options.map(option => option.value) as string[]);
            }
        } else {
            setSelectedValues(values);
        }
    };

    const isAllSelected = selectedValues.length === options.length;
    const displayValues = isAllSelected ? [allOption.value] : selectedValues;
    const displayData = data?.filter((item) => {
        if (!selectedValues || selectedValues.length === 0) {
            return true;
        }
        return selectedValues?.includes(item.category);
    })
    return (
        <div className={s.wrap}>
            <div className={s.header}>
                <div className={s.label}>
                    Categories
                </div>
                <div className={s.content}>
                    <Select
                        mode="multiple"
                        placeholder="Select options"
                        value={displayValues}
                        onChange={handleChange}
                        maxTagCount={"responsive"}
                        style={{ width: '500px' }}
                        dropdownRender={menu => (
                            <div className={s.selectWrap}>
                                <Checkbox
                                    checked={isAllSelected}
                                    indeterminate={selectedValues.length > 0 && selectedValues.length < options.length}
                                    onChange={() => handleChange(isAllSelected ? [] : options.map(option => option.value) as string[])}
                                    rootClassName={s.checkWrap}
                                    style={{ padding: '4px 8px' }}
                                >
                                    {allOption.label}
                                </Checkbox>
                                <div style={{ borderTop: '1px solid #f0f0f0' }}>
                                    {menu}
                                </div>
                            </div>
                        )}
                    >
                        {options.map(opt => (
                            <Select.Option key={opt.value} value={opt.value}>
                                {opt.label}
                            </Select.Option>
                        ))}
                    </Select>
                    <div
                        onClick={() => {
                            setSelectedValues([])
                        }}
                        className={s.clearText}>
                        Clear filters
                    </div>
                </div>
            </div>
            <div className={s.values}>
                {
                    displayData?.map((item: TResource) => {
                        const cateIndex = allCategory.indexOf(item.category);
                        const targetColor = colors[cateIndex % colors.length];
                        return (
                            <div className={s.item} key={item.title}>
                                <div className={s.top}>
                                    <div className={s.c}>
                                        <Tag color={targetColor} className={s.tag}>
                                            {item.category}
                                        </Tag>
                                    </div>
                                    <div className={s.time}>
                                        {item.updateAt ? "Last modified:" + getTimeStr(dayjs(item.updateAt), "MMM DD, YYYY") : ''}
                                    </div>
                                </div>
                                <div className={s.vTitle}>
                                    {item.title}
                                </div>
                                <div className={s.vContent}>
                                    {item.description}
                                </div>
                                <div
                                    onClick={() => {
                                        setCurrentItem(item);
                                        setShowModal(true);
                                    }}
                                    className={s.btnWrap}>
                                    Learn more
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <Modal
                className={commonS.modalFixSetedHeightWrap}
                open={showModal}
                width={1000}
                footer={null}
                style={{ maxWidth: '90%' }}
                onCancel={() => {
                    setUrl('');
                    setShowModal(false)
                }}
                title={currentItem?.title}
                destroyOnClose
            >
                <div className={s.mWrap}>
                    {
                        url ?
                            <iframe src={url} className={s.iframe} /> :
                            <Spin spinning>
                                <div style={{ minHeight: '100px' }} />
                            </Spin>
                    }

                </div>
            </Modal>
        </div>
    );
};

export default ResourceCenter;
