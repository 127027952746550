import React from 'react';
import { Button } from 'antd';
import s from './s.module.less';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { getPPAChannelId } from 'utils/common';

const InitStep: React.FC<{ className: string; }> = ({
    className,
}) => {
    const navigate = useNavigate();
    return (
        <div className={className}>
            <div className={s.content}>
                <p>
                    Opt into the free Klarity Health Pay-per-Appointment channel to
                    attract more patients. You only need to pay when there is an
                    appointment scheduled on your calendar.
                </p>
            </div>
            <div className={s.buttonWrap}>
                <Button
                    type="primary"
                    onClick={() => {
                        navigate(
                            `${PATH.DASHBOARD}/${PATH.CHANNEL_DETAIL}?channelId=${getPPAChannelId()}`,
                        );
                    }}
                >
                    Opt-in
                </Button>
            </div>
        </div>
    );
};

export default InitStep;
