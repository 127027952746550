import React, { useEffect, useState } from 'react';
import s from './s.module.less';
import SpecialtiesConditions from 'components/HelloKlarityFormComponents/SpecialtiesConditions';
import MyPhilosophy from 'components/PracticeFrontFromComponents/MyPhilosophy';
import { UniprofileFormData } from 'types/provider';
import { getDraftData } from 'utils/helloklarity';
import { IDraftStorage } from 'types/helloKlarity';
import MedicationsPrescribed from '../MedicationsPrescribed';
import { EditModuleEnum } from 'types/practiceFront';
import { ISaveGenericInfoReqInput } from 'types/operation';

interface IProps {
    id: number;
    providerId: number;
    setSpecialtiesIsEdting?: (status: boolean) => void;
    specialtiesIsEdting?: boolean;
    providerData: UniprofileFormData;
    refetch?: () => void;
    onSaveSuccess?: (value: string[]) => void;
    onSubmit?: (data: any, field: EditModuleEnum, callback?: () => void) => void;
    genericDetails?: ISaveGenericInfoReqInput;
}

const Specialties = ({
    genericDetails,
    providerId,
    specialtiesIsEdting,
    setSpecialtiesIsEdting,
    providerData,
    refetch,
    onSaveSuccess,
    onSubmit,
}: IProps) => {
    const [specialtyHighlightListData, setSpecialtyHighlightList] = useState<string[]>([]);
    const { specialtyList = [], conditionTreatedList = [], treatmentApproach, treatmentPhilosophy, specialtyHighlightList = [] } = providerData?.practice || {};

    useEffect(() => {
        const draftData: IDraftStorage = getDraftData(providerId);
        const { specialtiesHighlights } = draftData || {};

        if (specialtiesHighlights) {
            const draftSpecialtiesHighlights = specialtiesHighlights.split(',');
            setSpecialtyHighlightList(draftSpecialtiesHighlights);
        } else {
            setSpecialtyHighlightList(specialtyHighlightList);
        }
    }, [providerId, specialtyHighlightList]);

    const specialtiesApproaches = {
        specialtyList,
        conditionTreatedList,
    };

    const myPhilosophy = {
        treatmentApproach,
        treatmentPhilosophy,
    };

    return (
        <div className={s.wrap}>
            <div className={s.editItem}>
                <SpecialtiesConditions onSaveSuccess={onSaveSuccess} specialtyHighlightList={specialtyHighlightListData} providerId={providerId} setSpecialtiesIsEdting={setSpecialtiesIsEdting} isEditing={specialtiesIsEdting} initFormValue={specialtiesApproaches} refetch={refetch} />
            </div>
            <div className={s.editItem}>
                <MedicationsPrescribed
                    prescribableMedications={genericDetails?.prescribableMedications}
                    nonPrescribableMedications={genericDetails?.nonPrescribableMedications}
                    field={EditModuleEnum.GENERIC_MEDICATIONS_PRESCRIBED}
                    onSubmit={onSubmit}
                />
            </div>
            <div className={s.editItem}>
                <MyPhilosophy readonly onSubmit={() => {}} onEditStatusChange={() => {}} initFormValue={myPhilosophy} />
            </div>
        </div>
    );
};

export default Specialties;
