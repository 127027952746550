// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__container--T50on {
  padding: 35px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.s-module__container--T50on img {
  margin-bottom: 14px;
  width: 136px;
  height: 136px;
}
.s-module__container--T50on .s-module__title--dEgZT {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
}
.s-module__container--T50on .s-module__description--YS5BJ {
  margin-bottom: 13px;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
}
.s-module__container--T50on .s-module__button--Y9Hxn {
  width: 122px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ReputationDashboradPage/components/ReplyUpgradeTipModal/s.module.less"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AANA;EAQQ,mBAAA;EACA,YAAA;EACA,aAAA;AACR;AAXA;EAaQ,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;AACR;AAlBA;EAoBQ,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;AACR;AA3BA;EA6BQ,YAAA;AACR","sourcesContent":[".container {\n    padding: 35px 0;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n    img {\n        margin-bottom: 14px;\n        width: 136px;\n        height: 136px;\n    }\n    .title {\n        font-weight: 600;\n        font-size: 20px;\n        line-height: 26px;\n        letter-spacing: 0px;\n        text-align: center;\n    }\n    .description {\n        margin-bottom: 13px;\n        font-family: Inter;\n        font-weight: 400;\n        font-size: 14px;\n        line-height: 22px;\n        letter-spacing: 0px;\n        text-align: center;\n    }\n    .button {\n        width: 122px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `s-module__container--T50on`,
	"title": `s-module__title--dEgZT`,
	"description": `s-module__description--YS5BJ`,
	"button": `s-module__button--Y9Hxn`
};
export default ___CSS_LOADER_EXPORT___;
