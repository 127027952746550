import { EDISPUTE_STATUS } from 'types/common';

export enum ETimeType {
    THIS_WEEK = 'THIS_WEEK',
    LAST_30_DAYS = 'LAST_30_DAYS',
    LAST_90_DAYS = 'LAST_90_DAYS',
}

export enum ESortType {
    MOST_RECENT = 'MOST_RECENT',
    HIGHTEST_RATED = 'HIGHEST_RATING',
    LOWEST_RATED = 'LOWEST_RATING',
}

export enum EOpenType {
    BOTH_PUBLIC_AND_PRIVATE = 'ALL',
    ONLY_PUBLIC_REVIEWS = 'PUBLIC',
    ONLY_PRIVATE_REVIEWS = 'PRIVATE',
}

export enum EDisputeStatusType {
    ALL = 'ALL',
    INVALID = EDISPUTE_STATUS.INVALID,
    VALID = EDISPUTE_STATUS.VALID,
    INVESTIGATION = EDISPUTE_STATUS.INVESTIGATION,
}

export type TReviewComment = {
    id: number,
    commentMemberId: number,
    commentMemberType: string, // "BP_BOOKING_CLIENT",
    rootId: number,
    replyToId?: string,
    likeCount: number,
    isPublic: boolean,
    isSticky: boolean,
    stickyAt?: string,
    isAnonymous: boolean,
    isApprove: boolean,
    approveAt?: string, // null,
    content: string, // "good",
    createAt: string, // "2024-07-22T10:20:59",
    createBy: number,
    updateAt: string, // "2024-07-22T10:20:59",
    isDeleted: boolean,
};

export enum EReviewSource {
    PROVIDER_IMPORT = 'PROVIDER_IMPORT',
    EHR_BOOKING = 'EHR_BOOKING',
    SURVEY_LINK = 'SURVEY_LINK',
}

export type TReviewItem = {
    // verified: boolean,
    // needReply?: boolean,
    // name?: string,
    // content?: string,
    // isPrivate: boolean,
    // tags?: string[],
    // createTime?: string, //2023-12-05T06:32:15.000+00:00
    // id: number,
    // score: number,
    // feedback?: string,
    // feedbackTime?: string,

    id: number,
    uuid: string, // "follow_hight_51",
    bpProviderId: number, // 51,
    intakeqProviderId: string, // "",
    providerSource: string, // "E",
    commentId: number, // 15,
    rating: number, // 5.0,
    tags: string, //"Friendly, good",
    isPublic: boolean,
    isAnonymous: boolean,
    source?: EReviewSource, // "EHR_BOOKING",
    sourceId: string, // "50",
    reviewType: string, // "INITIAL_VISIT",
    contentLength: number,
    isReplied: boolean,
    repliedAt?: string,
    isNeedReplied: boolean,
    isApprove: boolean,
    approveAt?: string,
    reviewStatus: 'S' | 'I',
    submitAt: string;// "2024-07-23T10:20:59",
    comment?: TReviewComment,
    disputeStatus?: EDISPUTE_STATUS,
    disputeSubmitAt?: string,
    disputeDecisionAt?: string,
    disputeReasonDetail?: string,
    disputeReasonType?: string,
    disputeSupportFile?: Record<string, string>,
    disputeDecisionReason?: string,
    reviewId?: number,
    reviewerEmail?: string,
    reviewerPhone?: string | null,
};

export type TOverviewData = {
    averageSurveyRating: number,
    preAverageSurveyRating: number,
    averageSurveyRatingRate: number,

    feedbackReceivedCount: number,
    preFeedbackReceivedCount: number,
    feedbackReceivedRate: number,

    feedbackRepliedCount: number,
    preFeedbackRepliedCount: number,
    feedbackRepliedRate: number,

    providerName?: string
};
