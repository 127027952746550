import React, { useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import { Button, Form } from 'antd';
import { Desktop } from 'assets/preview/Desktop';
import { Mobile } from 'assets/preview/Mobile';
import os from 'assets/preview/OS.svg';
import surveyBanner from 'assets/survey/surveyBanner.png';
import dynamicIsland from 'assets/survey/dynamicIsland.png';
import { generateKiwiReviewLink, generateKlarityReviewLink } from 'utils/common';
import { handleProviderNameReplacement } from 'utils/survey';
import { Provider, HomeInfo } from 'types/provider';
import ReviewFlowSteps from 'components/ReviewFlowSteps';
import { MyChannelsData } from 'types/channel';

type IFormData = {
    subject: string;
    content: string;
};

type IProps = {
    initFormValue: IFormData
    providerData?: Provider;
    setMinimalSurveyScore: (score: { score: number, finished: boolean }) => void;
    handleSubmitMinimalSurveyScore: () => void;
    setSurveyJourneyIndex: (index: number) => void;
    surveyJourneyIndex: number;
    myChannels?: MyChannelsData;
    isKlarityUser: boolean;
    homeInfo?: HomeInfo;
};

const Invitation = ({
    initFormValue,
    providerData,
    setMinimalSurveyScore,
    handleSubmitMinimalSurveyScore,
    setSurveyJourneyIndex,
    surveyJourneyIndex,
    myChannels,
    isKlarityUser,
    homeInfo,
}: IProps) => {
    const [formInstance] = Form.useForm<IFormData>();
    const [isMobileMode, setIsMobileMode] = useState(false);
    const [email, setEmail] = useState('');
    const [showReviewFlow, setShowReviewFlow] = useState(false);
    const [iframeUrl, setIframeUrl] = useState('');

    // const host = isProductionEnv() ? 'https://provider.kiwihealth.com' : 'https://dev.d31frxe1u41op.amplifyapp.com';
    // const host = isProductionEnv() ? 'https://provider.kiwihealth.com' : 'http://localhost:3008';
    useEffect(() => {
        if (initFormValue) {
            const subject = handleProviderNameReplacement(initFormValue.subject, providerData);
            const content = handleProviderNameReplacement(initFormValue.content, providerData);
            setEmail(content);
            formInstance.setFieldsValue({
                subject,
                content,
            });
        }
    }, [initFormValue, formInstance, providerData]);

    useEffect(() => {
        let stepIndex = surveyJourneyIndex;

        if (surveyJourneyIndex === 1) {
            stepIndex = 2;
        }
        if (surveyJourneyIndex === 2) {
            stepIndex = 5;
        }
        const url = isKlarityUser ? generateKlarityReviewLink(homeInfo?.uidPublic) : generateKiwiReviewLink(homeInfo?.uidPublic);

        setIframeUrl(`${url}&step=${stepIndex}&isPreview=true`);

        setShowReviewFlow(surveyJourneyIndex !== 0);
    }, [homeInfo, isKlarityUser, surveyJourneyIndex]);

    const handleNext = (index: number, selectedValue?: number) => {
        if (selectedValue) {
            setMinimalSurveyScore({
                score: selectedValue,
                finished: true,
            });
        }
    };

    const hasListedChannel = useMemo(() => {
        return !!(myChannels && myChannels.allList && myChannels.allList.length > 0);
    }, [myChannels]);

    return (
        <div className={s.wrap}>
            <div className={s.left}>
                <div className={s.title}>Step 1</div>
                <div className={s.subTitle}>Overview of patient’s survey journey</div>
                <div className={s.body}>
                    <ReviewFlowSteps
                        hasListedChannel={hasListedChannel}
                        onNext={handleNext}
                        handleSubmitMinimalSurveyScore={handleSubmitMinimalSurveyScore}
                        setSurveyJourneyIndex={setSurveyJourneyIndex}
                        surveyJourneyIndex={surveyJourneyIndex}
                    />
                </div>
            </div>
            <div className={s.preview}>
                <div className={s.modeWrap}>
                    <span
                        onClick={() => {
                            setIsMobileMode(false);
                        }}
                        className={isMobileMode ? '' : s.active}
                    >
                        <Desktop />
                    </span>
                    <span
                        onClick={() => {
                            setIsMobileMode(true);
                        }}
                        className={isMobileMode ? s.active : ''}
                    >
                        <Mobile />
                    </span>

                </div>
                <div
                    className={isMobileMode ? `${s.iframeWrap} ${s.iframeWrapMobile}` : s.iframeWrap}
                >
                    <div className={s.topBar}>
                        {
                            isMobileMode ?
                                <div className={s.mobileHeader}>
                                    <img className={s.island} src={dynamicIsland} />
                                </div>
                                : <img src={os} />
                        }
                    </div>
                    {
                        showReviewFlow ? (
                            <div className={s.iframePreviewContent}>
                                <div className={s.iframeMask} />
                                { !hasListedChannel && surveyJourneyIndex === 2 && <div className={s.nonChannelMask}><div className={s.nonChannelTip}>Not available due to lack of channels</div></div> }
                                <iframe className={s.iframe} title="previewIframe" id="previewIframe" src={iframeUrl} />
                            </div>
                        ) : (
                            <div className={s.previewContent}>
                                <div className={s.scrollContent}>
                                    <img src={surveyBanner} />
                                    <div className={s.content}>
                                        <div className={s.text}>
                                            <p>Hello [Patient Name],</p>
                                            <br />
                                            <div dangerouslySetInnerHTML={{ __html: email }} />
                                            <br />
                                            <Button type="primary" className={s.surveyButton}>Open survey</Button>
                                            <br />
                                            <br />
                                            <p>Warm regards,</p>
                                            <p>{handleProviderNameReplacement('Kiwi Health in partnership with [Provider\'s Name]', providerData)}</p>
                                            <br />
                                            <br />
                                            {/* <p className={s.tip}>Please note: This email is for the intended recipient only. Kindly delete if received in error. Your feedback remains anonymous unless you allow the provider to follow up.  Anticipate an automated follow-up for additional input. Do not reply to this email. </p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default Invitation;
