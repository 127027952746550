import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './s.module.less';
import { Spin } from 'antd';
// import Introduction from './components/Introduction';
import SurveyInvitation from './components/Configration/Survey/Invitation/Invitation';
import SurveyIntro from './components/Configration/Survey/Intro/Intro';
import PatientIntro from './components/Configration/Patient/Intro/Intro';
import PatientImprot from './components/Configration/Patient/Import/Import';

import { reputationProviderData } from 'data/mockData';
import Footer from './components/Footer';
import { saveReviewTrigger } from 'api/survey';
import useGetSurveyEmailTemplate from 'hooks/survey/useGetSurveyEmailTemplate';
import useProviderFetch from 'hooks/useProviderFetch';
import { useNavigate } from 'react-router-dom';
import { PATH } from 'constants/path';
import { setImportedPatients, setReputationSetupStep, setStartEditReview, setStartEditSurveyForm } from 'utils/localstore';
import { isFreeUser, shouldShowKlarityPlanUI } from 'utils/provider';
import SetProviderInfoModal from './components/SetProviderInfoModal';
import useLoadBasicData from 'hooks/useLoadBasicData';
import { reputationAddProfile } from 'api/provider';
import { IAddProfile } from 'types/reputation';
import { ESurveyStep } from 'types/survey';
import useGetMyChannels from 'hooks/useGetMyChannels';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';

const ReputationConfigPage = () => {
    const [homeInfo, loadingHome] = useProviderHomeInfo();
    const navigate = useNavigate();
    // const surveyFormSetupRef = useRef();
    const [currentStep, setCurrentStep] = useState(1);
    const [emaillTeplateData, emailTeplateLoading] = useGetSurveyEmailTemplate();
    const [myChannels, loadingMychannels] = useGetMyChannels();
    const [providerData, providerLoading,,refetchProvider] = useProviderFetch();
    const [saveLoading, setSaveLoading] = useState(false);
    // const [hasConfig] = useGetProviderReputationStatus();
    const [loadSettingData] = useLoadBasicData();
    const isKlarityUser = shouldShowKlarityPlanUI(homeInfo);

    const [isReputationEdit, setIsReputationEdit] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [addProfileLoading, setAddProfileLoading] = useState(false);

    const [minimalSurveyScore, setMinimalSurveyScore] = useState({
        score: 4,
        finished: false,
    });

    const [surveyJourneyIndex, setSurveyJourneyIndex] = useState(0);

    const { invitationEmail } = reputationProviderData;

    const currentStepInStepBar = useRef<Record<number, number>>({
        [ESurveyStep.INTRODUCTION]: -1,
        [ESurveyStep.SURVEY_INTRO]: -1,
        [ESurveyStep.SURVEY_INVITATION]: 1,
        [ESurveyStep.PATIENT_INTRO]: 3,
        [ESurveyStep.PATIENT_IMPORT]: 4,
    });

    const [currentStepBarIndex, setCurrentStepBarIndex] = useState(-1);

    useEffect(() => {
        setImportedPatients('');
    }, []);

    useEffect(() => {
        const stepbars = document.querySelectorAll('[data-name="stepbar"]');

        for (let i = 0; i < stepbars.length; i++) {
            stepbars[i].classList.remove(s.actived);
            if (i < currentStepBarIndex) {
                stepbars[i].classList.add(s.actived);
            }
        }
    }, [currentStepBarIndex]);

    useEffect(() => {
        if (currentStep < ESurveyStep.SURVEY_INVITATION) {
            setCurrentStepBarIndex(-1);
        } else {
            const index = currentStepInStepBar.current[currentStep];

            if (index) {
                if (currentStep < ESurveyStep.SURVEY_INVITATION) {
                    setCurrentStepBarIndex(-1);
                }
                setCurrentStepBarIndex(index);
            }
        }
    }, [currentStep]);

    useEffect(() => {
        return () => {
            setStartEditSurveyForm();
            setStartEditReview();
        };
    }, []);

    // useEffect(() => {
    //     if (!isReputationEdit && !hasConfig) {
    //         const step = getReputationSetupStep();

    //         if (step) {
    //             setCurrentStep(step ? Number(step) : 1);
    //         }
    //     }
    // }, [hasConfig, isReputationEdit]);

    // useEffect(() => {
    //     if (isSurverEdit) {
    //         setCurrentStep(ESurveyStep.SURVEY_INTRO);
    //     } else if (isReviewEdit) {
    //         setCurrentStep(ESurveyStep.REVIEW_INTRO);
    //     }

    //     setIsReputationEdit(!!isSurverEdit || !!isReviewEdit);
    // }, [isReviewEdit, isSurverEdit]);

    // useEffect(() => {
    //     if (!isReputationEdit && !hasConfig) {
    //         setReputationSetupStep(currentStep.toString());
    //     }
    // }, [isReputationEdit, currentStep, hasConfig]);

    useEffect(() => {
        loadSettingData();
    }, [loadSettingData]);

    const handleSubmitMinimalSurveyScore = useCallback(async () => {
        try {
            const res = await saveReviewTrigger({
                minimalSurveyScore: minimalSurveyScore.score,
            });

            if (res && !res.error) {
                setCurrentStep(ESurveyStep.PATIENT_INTRO);
            }
        } catch (e) {
            console.error(e);
        }
    }, [minimalSurveyScore.score]);

    const handleConfigSuccess = useCallback(() => {
        if (!isFreeUser()) {
            setReputationSetupStep();
        }
        navigate(`/${PATH.REPUTATION_CONFIG_SUCCESS}`);
    }, [navigate]);

    const handleNextPageWithoutForm = useCallback(() => {
        if (currentStep === ESurveyStep.SURVEY_INTRO && (!providerData || !providerData.firstName)) {
            setShowProfileModal(true);
        } else {
            const step = currentStep + 1;
            setCurrentStep(step);
        }
    }, [currentStep, providerData]);

    const onNext = useCallback(async () => {
        if (currentStep > ESurveyStep.SURVEY_INVITATION) {
            handleConfigSuccess();
        } else if (minimalSurveyScore.finished) {
            handleSubmitMinimalSurveyScore();
        } else {
            handleNextPageWithoutForm();
        }
    }, [currentStep, handleConfigSuccess, handleNextPageWithoutForm, handleSubmitMinimalSurveyScore, minimalSurveyScore.finished]);

    const backToPreviousPage = useCallback(() => {
        if (currentStep === ESurveyStep.SURVEY_INVITATION && surveyJourneyIndex > 0) {
            const journeyStep = surveyJourneyIndex - 1 < 0 ? 0 : surveyJourneyIndex - 1;
            setSurveyJourneyIndex(journeyStep);
            if (journeyStep !== 3) {
                setMinimalSurveyScore({
                    ...minimalSurveyScore,
                    finished: false,
                });
            }
        } else {
            const step = currentStep - 1;
            setCurrentStep(step < 1 ? 1 : step);
        }
    }, [currentStep, minimalSurveyScore, surveyJourneyIndex]);

    const handleSkipButtonClick = useCallback(() => {
        setCurrentStep(6);
    }, []);

    const handleSubmitProfile = useCallback(async (data: IAddProfile) => {
        if (providerData && providerData.id) {
            setAddProfileLoading(true);
            try {
                const res = await reputationAddProfile(providerData.id, data);

                if (res && !res.error) {
                    setShowProfileModal(false);
                    await refetchProvider();

                    const step = currentStep + 1;
                    setCurrentStep(step);
                }
            } catch (e) {
                console.error(e);
            }
            setAddProfileLoading(false);
        }
    }, [currentStep, providerData, refetchProvider]);

    const handleAddPatientsManually = useCallback(() => {
        setCurrentStep(ESurveyStep.PATIENT_IMPORT);
    }, []);

    const pageRender = useMemo(() => {
        let content = null;

        const commonProps = {
            hideIndex: isReputationEdit,
        };

        switch (currentStep) {
            case ESurveyStep.SURVEY_INTRO:
                content = <SurveyIntro {...commonProps} />;
                break;
            case ESurveyStep.SURVEY_INVITATION:
                content = <SurveyInvitation
                    homeInfo={homeInfo}
                    isKlarityUser={isKlarityUser}
                    providerData={providerData}
                    initFormValue={emaillTeplateData || invitationEmail}
                    setMinimalSurveyScore={setMinimalSurveyScore}
                    handleSubmitMinimalSurveyScore={handleSubmitMinimalSurveyScore}
                    setSurveyJourneyIndex={setSurveyJourneyIndex}
                    surveyJourneyIndex={surveyJourneyIndex}
                    myChannels={myChannels}
                />;
                break;
            case ESurveyStep.PATIENT_INTRO:
                content = <PatientIntro {...commonProps} onAddPatientsManually={handleAddPatientsManually} />;
                break;
            case ESurveyStep.PATIENT_IMPORT:
                content = <PatientImprot />;
                break;
            default: content = null;
        }

        return content;
    }, [currentStep, emaillTeplateData, handleAddPatientsManually, handleSubmitMinimalSurveyScore, homeInfo, invitationEmail, isKlarityUser, isReputationEdit, myChannels, providerData, surveyJourneyIndex]);

    const spinning = !!(emailTeplateLoading || providerLoading || loadingMychannels || loadingHome);

    const showBack = useMemo(() => {
        return currentStep !== ESurveyStep.SURVEY_INTRO;
    }, [currentStep]);

    const showSkipButton = useMemo(() => {
        // return currentStep === ESurveyStep.PATIENT_INTRO || currentStep === ESurveyStep.PATIENT_IMPORT;
        return false;
    }, []);

    // 临时方案
    const stepBarRender = useMemo(() => {
        return (
            <div className={s.step}>
                <div className={`${s.stepBar} ${s.wrapBar}`}>
                    <div data-name="stepbar" className={`${s.stepBar}`} />
                </div>
                <div className={`${s.stepBar} ${s.wrapBar}`}>
                    <div data-name="stepbar" className={s.stepBar} />
                </div>
            </div>
        );
    }, []);

    return (
        <div className={s.wrap}>
            <Spin spinning={spinning}>
                <SetProviderInfoModal
                    loading={addProfileLoading}
                    show={showProfileModal}
                    onOk={handleSubmitProfile}
                    onCannel={() => setShowProfileModal(false)}
                />
                {pageRender}
                {stepBarRender}
                <Footer
                    submitText={currentStep < ESurveyStep.PATIENT_INTRO ? 'Next' : 'Submit'}
                    nextDisabled={currentStep === ESurveyStep.SURVEY_INVITATION && !minimalSurveyScore.finished}
                    showSkipButton={showSkipButton}
                    showBack={showBack}
                    loading={saveLoading}
                    onBackClick={backToPreviousPage}
                    onNextClick={onNext}
                    onSkipClick={handleSkipButtonClick}
                />
            </Spin>
        </div>
    );
};

export default ReputationConfigPage;
