// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--uXrZo {
  padding: 0 32px;
}
.s-module__wrap--uXrZo .s-module__title--pYQjF {
  margin-bottom: 16px;
  color: var(--gray-700, #374151);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  display: flex;
  align-items: center;
}
.s-module__wrap--uXrZo .s-module__title--pYQjF::after {
  content: '*';
  color: var(--red-500, #EF4444);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/ReputationDashboradPage/components/SettingsOverviewModal/s.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAFA;EAII,mBAAA;EACA,+BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACF,aAAa;EAAX,aAAA;EACA,mBAAA;AAEJ;AAAI;EACE,YAAA;EACA,8BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAEN","sourcesContent":[".wrap {\n  padding: 0 32px;\n\n  .title {\n    margin-bottom: 16px;\n    color: var(--gray-700, #374151);\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 20px; /* 142.857% */\n    display: flex;\n    align-items: center;\n\n    &::after {\n      content: '*';\n      color: var(--red-500, #EF4444);\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: 20px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--uXrZo`,
	"title": `s-module__title--pYQjF`
};
export default ___CSS_LOADER_EXPORT___;
