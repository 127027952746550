import React, { useMemo } from 'react';
import s from './s.module.less';
import { Collapse, Tabs } from 'antd';
import ResourceCenter from './components/ResourceCenter';
import useKlarityResource from 'hooks/useKlarityResource';
import { getHelpCenterActiveKey, setHelpCenterActiveKey } from 'utils/sessionStorage';
import useProviderServiceFlag from 'hooks/useProviderServiceFlag';

const HelpCenter = () => {
    const [activedKey, setActivedKey] = React.useState<string>('1');
    const [resourceData, loading] = useKlarityResource();
    const [flag] = useProviderServiceFlag();
    const isKlarity = !!flag?.klarityServiceFlag;
    const [currentActiveKey, setCurrentActiveKey] = React.useState<string[] | undefined>(['1', '2', '3', '4']);

    const hasResource = resourceData && resourceData.length > 0 && isKlarity;

    React.useEffect(() => {
        if (!resourceData) {
            return;
        }
        if (resourceData.length === 0) {
            setActivedKey('1');
        }
    }, [resourceData]);

    React.useEffect(() => {
        const key = getHelpCenterActiveKey();
        if (key) {
            setActivedKey(key);
            setHelpCenterActiveKey(undefined);
        }
    }, []);

    return (
        <div className={s.wrap}>
            <div className={s.header}>Help center</div>
            {/* <Collapse items={items} bordered={false} defaultActiveKey={['1', '2', '3', '4']} /> */}
            <Tabs activeKey={activedKey} onChange={(e) => setActivedKey(e)}>
                <Tabs.TabPane
                    tab={
                        <div className={`${s.tabItem} ${activedKey === '1' ? s.actived : ''}`}>
                            <div className={s.icon} />
                            <div className={s.tabText}>Frequently asked questions</div>
                        </div>
                    }
                    key="1"
                >
                    <>
                        <Collapse
                            onChange={(key) => {
                                setCurrentActiveKey(key as string[]);
                            }
                            }
                            bordered={false} defaultActiveKey={currentActiveKey}>
                            <Collapse.Panel
                                header={
                                    <div className={s.collapseHeader}>
                                        What is Kiwi Health?
                                        <div className={`${s.iconBox} ${currentActiveKey?.includes("1") ? s.expandIcon : ''}`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M19 9L12 16L5 9" stroke="#4B5563" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                }
                                key="1"
                                showArrow={false}
                            >
                                <div className={s.text}>
                                    <p>Kiwi Health is a revolutionary software product designed to help healthcare providers grow their practice in ways they never thought possible. Kiwi Health combines AI-driven innovation with healing services to optimize marketing and administrative tasks for health professionals. Our platform provides an efficient and secure way to offer services via telehealth and in-person, and we partner with leading AI and telehealth technology providers to deliver the best solutions.</p>
                                    <p>You could learn more from this video on YouTube: <a href='https://www.youtube.com/watch?v=reb_QeK5TVw' target="_blank">Kiwi Health: Grow your practice with ease</a></p>
                                </div>
                            </Collapse.Panel>
                            <Collapse.Panel
                                header={
                                    <div className={s.collapseHeader}>
                                        How can AI help me grow my practice?
                                        <div className={`${s.iconBox} ${currentActiveKey?.includes("2") ? s.expandIcon : ''}`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M19 9L12 16L5 9" stroke="#4B5563" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                }
                                key="2"
                                showArrow={false}
                            >
                                <div className={s.text}>
                                    <p>Our AI-driven marketing solutions optimize your platform listings on popular sites such as Psychology Today, Google My Business, Yelp, WebMD, Meta (Facebook), and more. This helps you enhance your online presence, attract more clients, and grow your practice. You can enable our AI-powered Front Desk Receptionist Chatbot on your own website to handles basic questions and scheduling, maximizing efficiency and patient satisfaction.</p>
                                </div>
                            </Collapse.Panel>
                            <Collapse.Panel
                                header={
                                    <div className={s.collapseHeader}>
                                        Do I need technical knowledge or be tech savvy to use KiwiHealth?
                                        <div className={`${s.iconBox} ${currentActiveKey?.includes("3") ? s.expandIcon : ''}`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M19 9L12 16L5 9" stroke="#4B5563" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                }
                                key="3"
                                showArrow={false}
                            >
                                <div className={s.text}>
                                    <p>No, you do not need technical knowledge to use Kiwi Health. The software is designed to be user-friendly and easy to navigate, so you can take advantage of all the features it has to offer without needing any technical expertise. We are also exploring the latest AI technologies on-site, so in the near future, you could simply type or speak to turn your intent into results.</p>
                                </div>
                            </Collapse.Panel>
                            <Collapse.Panel
                                header={
                                    <div className={s.collapseHeader}>
                                        How does Kiwi Health improve online reviews?
                                        <div className={`${s.iconBox} ${currentActiveKey?.includes("4") ? s.expandIcon : ''}`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M19 9L12 16L5 9" stroke="#4B5563" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                }
                                key="4"
                                showArrow={false}
                            >
                                <div className={s.text}>
                                    <p>The tool lets providers send review requests to patients after their visits. Patients are encouraged to share positive feedback online, while negative reviews are sent to a separate link for further communication and resolution to let providers concerns concerns directly.</p>
                                </div>
                            </Collapse.Panel>
                            <Collapse.Panel
                                header={
                                    <div className={s.collapseHeader}>
                                        What if I have more questions?
                                        <div className={`${s.iconBox} ${currentActiveKey?.includes("5") ? s.expandIcon : ''}`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M19 9L12 16L5 9" stroke="#4B5563" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </div>
                                    </div>
                                }
                                key="4"
                                showArrow={false}
                            >
                                <div className={s.text}>
                                    <ul>
                                        <li>Please visit: <a href='https://support.kiwihealth.com/support/solutions/folders/66000424990' target="_blank">https://support.kiwihealth.com/support/solutions/folders/66000424990</a></li>
                                        <li>Email our support team:  <a href='mailto:support@kiwihealth.com' target="_blank">support@kiwihealth.com </a></li>
                                    </ul>
                                </div>
                            </Collapse.Panel>
                        </Collapse>

                        {/* <div className={s.contact}>
                    <div className={s.label}>Contact us:</div>
                    <a href={`mailto:${Email.klarity_provider_support}`} target="_blank" rel="noreferrer">{Email.klarity_provider_support}</a>
                </div> */}
                    </>
                </Tabs.TabPane>
                {
                    hasResource &&
                    <Tabs.TabPane
                        tab={
                            <div className={`${s.tabItem} ${s.tabItem2} ${activedKey === '2' ? s.actived : ''}`}>
                                <div className={s.icon} />
                                <div className={s.tabText}>Klarity policies & resources</div>
                            </div>
                        }
                        key="2"
                    >
                        <ResourceCenter data={resourceData} />
                    </Tabs.TabPane>
                }
            </Tabs>
        </div>
    );
};

export default HelpCenter;
