import React from 'react';
import s from './s.module.less';
import { Button, Modal } from 'antd';
import Image2 from './images/2.png';
import { CALENDLY_TYPE } from 'types/common';

interface IProps {
    setOnboardingPopupStep: (step: number) => void;
    onClosePopup: () => void;
    onShowCalendly: () => void;
    providerCalendlyScheduleRecords?: string[];
}

const Webinar = ({
    setOnboardingPopupStep,
    onClosePopup,
    onShowCalendly,
    providerCalendlyScheduleRecords,
}: IProps) => {
    const hasDone = !!(providerCalendlyScheduleRecords?.includes(CALENDLY_TYPE.ONBOARDING));
    return (
        <div className={s.wrap}>
            <div className={s.playerBox}>
                <div className={s.closeIcon} onClick={onClosePopup} />
                <div className={s.player}>
                    <img src={Image2} alt="" />
                </div>
            </div>
            <div className={s.content}>
                {
                    hasDone ? (
                        <>
                            <div className={`${s.title} ${s.completeIcon}`}>Your webinar is scheduled</div>
                            <div className={s.desc}>{'You\'ve successfully booked your webinar. Get ready to learn how Kiwi can help grow your practice.'}</div>
                        </>
                    ) : (
                        <>
                            <div className={s.title}>Schedule webinar – learn how Kiwi works for you</div>
                            <div className={s.desc}>Discover how Kiwi can help you grow your patient leads, improve your online presence, and make practice management easier.</div>
                            <Button className={s.stepDirBtn} onClick={onShowCalendly}>Schedule now</Button>
                        </>
                    )
                }

            </div>
            <div className={s.footer}>
                <div className={s.step}>Step 2 of 5</div>
                <div className={s.btns}>
                    <Button style={{ width: '120px' }} onClick={() => setOnboardingPopupStep(1)}>Previous</Button>
                    <Button style={{ width: '120px' }} type="primary" onClick={() => setOnboardingPopupStep(3)}>Next</Button>
                </div>
            </div>
        </div>
    );
};

export default Webinar;
