import React from 'react';
import s from './s.module.less';
import { Button, Modal, Spin } from 'antd';
import Image4 from './images/4.png';
import useSubscriptionPlan from 'hooks/useSubscriptionPlan';
import { TSubscriptionItem, TSubscriptionType } from 'types/subscription';
import SubscriptionButton from 'components/SubscriptionButton';
import { openSubscriptionModal, openManageSubscriptionModal } from 'utils/globalLayerControl';

interface IProps {
    setOnboardingPopupStep: (step: number) => void;
    onClosePopup: () => void;
    isFreeUser?: boolean;
}

const GetPlan = ({
    setOnboardingPopupStep,
    onClosePopup,
    isFreeUser,
}: IProps) => {
    const [plansData, loadingSubscriptionPlan] = useSubscriptionPlan();
    const [item, setItem] = React.useState<TSubscriptionItem>();

    React.useEffect(() => {
        const bestValuePlan = plansData?.kiwiPlan?.find((plan) => plan.type === TSubscriptionType.GROWTH);
        setItem(bestValuePlan);
    }, [plansData]);

    return (
        <div className={s.wrap}>
            <Spin spinning={!!loadingSubscriptionPlan} />
            <div className={s.playerBox}>
                <div className={s.closeIcon} onClick={onClosePopup} />
                <div className={s.player}>
                    <img src={Image4} alt="" />
                </div>
            </div>
            <div className={s.content}>
                {
                    !isFreeUser ? (
                        <>
                            <div className={`${s.title} ${s.completeIcon}`}>{'You\'re on the growth plan'}</div>
                            <div className={s.desc}>Your growth plan is active. Keep an eye on your benefits and make the most of your plan!</div>
                            <Button className={s.stepDirBtn} onClick={() => openManageSubscriptionModal()}>Manage plan</Button>
                        </>
                    ) : (
                        <>
                            <div className={s.title}>Upgrade to Growth plan – expand your reach</div>
                            <div className={s.desc}>Upgrade to the Growth Plan to attract more patients and grow your practice. Choose a 6-month plan to enjoy 18% off — and don’t forget to use your coupon if you have one!</div>
                            {/* <Button className={s.stepDirBtn}>🚀 Start 6-month subscription to save 18%</Button> */}
                            {
                                item?.semiAnnualPlanId &&
                                <SubscriptionButton
                                    planId={item?.semiAnnualPlanId}
                                    size="large"
                                    loading={!!loadingSubscriptionPlan}
                                    // type="primary"
                                    className={s.stepDirBtn}
                                    block
                                    text="🚀 Start 6-month subscription to save 18%"
                                    plan={item}
                                />
                            }
                            <div className={s.painPlan} onClick={() => openSubscriptionModal(true)}>Check out all plans</div>
                        </>
                    )
                }
            </div>
            <div className={s.footer}>
                <div className={s.step}>Step 4 of 5</div>
                <div className={s.btns}>
                    <Button style={{ width: '120px' }} onClick={() => setOnboardingPopupStep(3)}>Previous</Button>
                    <Button style={{ width: '120px' }} type="primary" onClick={() => setOnboardingPopupStep(5)}>Next</Button>
                </div>
            </div>
        </div>
    );
};

export default GetPlan;
