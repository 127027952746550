import React, { useCallback, useEffect, useMemo } from 'react';
import s from './s.module.less';
import { Button, Checkbox, Modal, Switch } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { IKlarityProviderProgramEnroll } from 'types/programs';

interface IProps {
    programs?: IKlarityProviderProgramEnroll[];
    setPendingSubmitPrograms?: (programs: { programId: number, activeStatus: 'A' | 'D', displayStatus: 'A' | 'D', isDeleted: boolean }[]) => void;
}

const Program = ({
    programs,
    setPendingSubmitPrograms,
}: IProps) => {
    const [showRemoveWaring, setShowRemoveWaring] = React.useState<boolean>(false);
    const [checked, setChecked] = React.useState<boolean>(false);
    const [originalPrograms, setOriginalPrograms] = React.useState<IKlarityProviderProgramEnroll[]>([]);
    const [currentRemoveId, setCurrentRemoveId] = React.useState<number>(0);

    useEffect(() => {
        if (programs) {
            setOriginalPrograms(programs);
        }
    }, [programs]);

    useEffect(() => {
        if (originalPrograms.length > 0) {
            setPendingSubmitPrograms?.(originalPrograms.map((program) => {
                const { id, displayStatus, programStatus } = program;
                return {
                    programId: id,
                    displayStatus: displayStatus || 'D',
                    activeStatus: programStatus || 'D',
                    isDeleted: !!program.isDeleted,
                };
            }));
        }
    }, [originalPrograms]);

    const handleDisplayChange = useCallback((checked: boolean, id: number) => {
        setOriginalPrograms(originalPrograms.map((program) => {
            if (program.id === id) {
                return {
                    ...program,
                    displayStatus: checked ? 'A' : 'D',
                };
            }
            return program;
        }));
    }, [originalPrograms]);

    const handleConfirmRemove = useCallback(() => {
        setShowRemoveWaring(false);
        setChecked(false);
        setOriginalPrograms(originalPrograms.map((program) => {
            if (program.id === currentRemoveId) {
                return {
                    ...program,
                    programStatus: 'D',
                    isDeleted: true,
                };
            }
            return program;
        }));
    }, [currentRemoveId, originalPrograms]);

    const handleRemove = useCallback((id: number) => {
        setShowRemoveWaring(true);
        setCurrentRemoveId(id);
    }, []);

    const handleChangeActiveStatus = useCallback((id: number) => {
        setOriginalPrograms(originalPrograms.map((program) => {
            if (program.id === id) {
                return {
                    ...program,
                    programStatus: 'A',
                    isDeleted: false,
                };
            }
            return program;
        }));
    }, [originalPrograms]);

    const programRender = useMemo(() => {
        if (originalPrograms && originalPrograms.length > 0) {
            return originalPrograms.map((program) => {
                const { id, programName, programStatus, displayStatus } = program;
                const isActive = programStatus === 'A';

                return (
                    <div key={id} className={s.program}>
                        <div className={s.nameBox}>
                            <div className={`${s.status} ${isActive ? s.active : ''}`} />
                            <div className={s.text} title={programName}>{programName}</div>
                        </div>
                        <div className={s.actionBox}>
                            {
                                isActive ?
                                    <div className={s.removeBtn} onClick={() => handleRemove(id)}>Remove</div> :
                                    <div className={s.activeBtn} onClick={() => handleChangeActiveStatus(id)}>Set as active</div>
                            }
                            <Switch checked={displayStatus === 'A'} onChange={(e) => handleDisplayChange(e, id)} />
                            Display
                        </div>
                    </div>
                );
            });
        }

        return <div className={s.nonProgram}>No program added by the provider</div>;
    }, [originalPrograms]);

    return (
        <div className={s.wrap}>
            <Modal
                width={780}
                closable={false}
                open={showRemoveWaring}
                footer={
                    <div className={s.modalFooter}>
                        <Button onClick={() => {
                            setShowRemoveWaring(false);
                            setChecked(false);
                        }}
                        >Cancel
                        </Button>
                        <Button type="primary" disabled={!checked} onClick={handleConfirmRemove}>Confirm</Button>
                    </div>
                }
            >
                <div className={s.waringModalWrap}>
                    <div className={s.tipIcon} />
                    <div className={s.content}>
                        <div className={s.title}>Confirm program removal</div>
                        <div className={s.descBox}>
                            <div className={s.desc}>Removing a program is irreversible and will delete it from the provider's EHR. Please confirm the removal.</div>
                        </div>
                        <Checkbox onChange={(e: CheckboxChangeEvent) => setChecked(e.target.checked)}>`I confirm the removal of program name from the provider's account`.</Checkbox>
                    </div>
                </div>
            </Modal>
            <div className={s.title}>Klarity select program</div>
            <div className={s.programList}>
                {
                    programRender
                }
            </div>
        </div>
    );
};

export default Program;
