import React, { useMemo } from 'react';
import s from './s.module.less';
import SpecificBasicInfo from 'components/HelloKlarityFormComponents/SpecificBasicInfo';
import { EditModuleEnum } from 'types/practiceFront';
import { UniprofileFormData } from 'types/provider';
import { IBasicInfo, IGetSpecificConditionDetailResponse } from 'types/helloKlarity';
import AIGeneratedTags from '../AIGeneratedTags';
import { ISaveSpecificInfoReqListInput } from 'types/operation';

interface IProps {
    specificConditionDetail?: ISaveSpecificInfoReqListInput;
    providerData: UniprofileFormData;
    onSubmit?: (formValue, field: EditModuleEnum, callback?: () => void) => void;
    refetch: () => void;
    conditionId?: string;
}

const SpecificAbout = ({
    conditionId,
    specificConditionDetail,
    onSubmit,
    refetch,
    providerData,
    // setTabKey,
}: IProps) => {
    const { profile, id, practice } = providerData;
    const { aboutMe, headLines, tags } = specificConditionDetail || {};
    const { firstName, lastName, middleName, credential, title, yearExp, npi, primaryLanguage, foreignLanguage, photo } = profile;
    const basicInfo: IBasicInfo = {
        id,
        firstName: firstName as string,
        lastName: lastName as string,
        middleName,
        credential,
        headLine: headLines,
        title,
        yearExp,
        npi,
        intro: aboutMe,
        language: `${primaryLanguage}${foreignLanguage && foreignLanguage.length > 0 ? `, ${foreignLanguage.join(', ')}` : ''}`,
        photo,
        providerPractice: practice,
    };

    const commomProps = useMemo(() => ({
        onSubmit,
        refetch,
        onEditStatusChange: () => {},
    }), [onSubmit, refetch]);

    return (
        <div className={s.wrap}>
            <div className={s.editItem}>
                <SpecificBasicInfo
                    {...commomProps}
                    conditionId={conditionId}
                    initFormValue={basicInfo}
                />
            </div>
            <div className={s.editItem}>
                <AIGeneratedTags
                    field={EditModuleEnum.SPECIFIC_TAGS}
                    {...commomProps}
                    value={tags?.filter((e) => !!e) || []}
                    providerId={id}
                    conditionId={conditionId}
                />
            </div>
        </div>
    );
};

export default SpecificAbout;
