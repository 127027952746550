import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import { Modal, Form, message, Button } from 'antd';
import remove from 'lodash/remove';
import {
    EBookingListAction,
    IEditBookingLink,
    TOfferingItem,
    IFormData,
} from '../../type';
import copy from 'copy-to-clipboard';
import { getOfferingList } from 'api/operation';
import BookingLinkModal from 'components/BookingLinkModal';
import { EBookingLinkModalMode, ISpecialtyNServiceTypeResponse } from 'types/helloKlarity';
import BookingLinkTable from '../BookingLinkTable';
import { generateUUID } from 'utils/common';

interface IProps {
    specialtyNServiceTypeData?: ISpecialtyNServiceTypeResponse;
    data: IEditBookingLink[];
    // originalBookingData: IEditBookingLink[];
    providerId: number;
    errorMsg: string;
    updateBookingListData: (data: IEditBookingLink[], syncToHK?: boolean) => void;
    // refetch: (id: number) => void;
}

const BookingLink = ({
    specialtyNServiceTypeData,
    providerId,
    // originalBookingData,
    data,
    updateBookingListData,
    errorMsg,
}: // refetch,
IProps) => {
    // const [loading, setLoading] = useState(false);
    const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
    const [submiting, setSubmiting] = useState(false);
    const [form] = Form.useForm<IFormData>();
    const [packagesOptions, setPackagesOptions] = useState([]);

    const [editingData, setEditingData] = useState<IEditBookingLink>();
    const [tableData, setTableData] = useState<Record<string, IEditBookingLink[]>>();
    const [showBookingLinkTable, setShowBookingLinkTable] = useState(false);
    const [mode, setMode] = useState<EBookingLinkModalMode>();
    const [showBookinLinkModal, setShowBookinLinkModal] = useState(false);

    useEffect(() => {
        if (!showBookinLinkModal) {
            setMode(undefined);
        }
    }, [showBookinLinkModal]);

    const checkBookinkNameDisabled = () => {
        setPackagesOptions(packagesOptions.map((option) => {
            return ({
                ...option,
                disabled: !!data.find((e) => e.bookingLinkName === option.name),
            });
        }));
    };

    useEffect(() => {
        checkBookinkNameDisabled();
    }, [data]);

    const getList = async () => {
        const { data: listData } = await getOfferingList(providerId);

        const arr = (listData.data || []).map((item: TOfferingItem) => {
            return {
                content: item.name,
                dictKey: item.name,
                ...item,
                directBookingLink: item.immediate_purchase_url,
            };
        });
        // if (arr.length === 0) {
        //     arr.push({
        //         content: 'No booking link added',
        //         dictKey: '',
        //     });
        // }
        setPackagesOptions(arr);
    };

    useEffect(() => {
        if (data) {
            const separateOutSameSpecialty = data?.reduce((r: Record<string, IEditBookingLink[]>, c: IEditBookingLink) => {
                const specialtyName = c.specialty;
                let key = 'Others';

                if (specialtyName) {
                    key = specialtyName;
                }

                if (r[key] instanceof Array) {
                    r[key].push(c);
                } else {
                    r[key] = [c];
                }

                return r;
            }, {});

            setTableData(separateOutSameSpecialty);
        }
    }, [data]);

    useEffect(() => {
        getList();
    }, []);

    const handleEditBookingLink = useCallback((row: IEditBookingLink) => {
        setEditingData(row);
        setMode(EBookingLinkModalMode.LINK_EDIT);
        setShowBookinLinkModal(true);
    }, []);

    const handleRemoveBookingLink = useCallback((row: IEditBookingLink) => {
        remove(data, (item) => item.uuid === row.uuid);

        updateBookingListData(data);
        setShowBookinLinkModal(false);
    }, [data, updateBookingListData]);

    const additionLink = useMemo(() => {
        return (
            <div className={s.bookinLinkActionBtns}>
                <a
                    className={s.addLink}
                    onClick={() => {
                        setMode(EBookingLinkModalMode.LINK_SELECT);
                        setShowBookinLinkModal(true);
                    }
                    }
                >
                    Add
                </a>
                { data && data.length > 0 && (
                    <a className={s.addLink} onClick={() => setShowBookingLinkTable(true)}>
                        Review
                    </a>
                )}
            </div>
        );
    }, [data]);

    const handleCopyLink = useCallback((value?: IEditBookingLink) => {
        if (value) {
            const { directBookingLink, specialty, state, serviceType } = value;
            if (!directBookingLink || !specialty || !state || !serviceType) {
                message.error('Please enter all information to proceed');
                return;
            }
            copy(directBookingLink || '', {
                format: 'text/plain',
            });
            message.success('Copied to the clipboard');
        }
    }, []);

    const handleSave = useCallback((m: EBookingLinkModalMode, value: IEditBookingLink | IFormData) => {
        if (m === EBookingLinkModalMode.LINK_EDIT) {
            const newData = data.map((e) => {
                const { uuid } = value as IEditBookingLink;

                if (e.uuid === uuid) {
                    return {
                        ...e,
                        ...value,
                    };
                }

                return e;
            });
            updateBookingListData(newData);
        } else {
            const { bookingList } = value as IFormData;
            updateBookingListData([
                ...data,
                ...(bookingList.map((item) => ({
                    ...item,
                    uuid: item.uuid || generateUUID(),
                }))),
            ]);
        }

        setShowBookinLinkModal(false);
    }, [data, updateBookingListData]);

    return (
        <div className={`${s.wrap} ${errorMsg ? s.errorWrap : ''}`}>

            <div className={s.wrapperHeader}>
                <div className={s.title}>
                    <div className={s.text}>Direct booking links</div>
                    { data && data.length > 0 && <div className={s.linkNumbers}>{`${data.length} links added`}</div> }
                </div>
                {additionLink}
            </div>
            <div className={s.errorContent}>{errorMsg}</div>

            {/* 新增popup*/}
            { showBookinLinkModal &&
                <BookingLinkModal
                    providerId={providerId}
                    open={showBookinLinkModal}
                    specialtyNServiceTypeData={specialtyNServiceTypeData}
                    editingData={editingData}
                    linkOptions={packagesOptions}
                    onSave={handleSave}
                    mode={mode}
                    // showModal={() => setShowBookinLinkModal(true)}
                    hideModal={() => setShowBookinLinkModal(false)}
                    onRemove={handleRemoveBookingLink}
                    onCopyLink={handleCopyLink}
                />
            }
            {/* review popup*/}
            <Modal
                title="Direct booking links"
                open={showBookingLinkTable}
                width={900}
                maskClosable={false}
                footer={
                    <Button onClick={() => {
                        setShowBookingLinkTable(false);
                    }}
                    >Cancel
                    </Button>
                }
                onCancel={() => {
                    setShowBookingLinkTable(false);
                }}
                okText={null}
            >
                <BookingLinkTable
                    data={tableData}
                    onEdit={handleEditBookingLink}
                    onRemove={handleRemoveBookingLink}
                    onCopyLink={handleCopyLink}
                />
            </Modal>
        </div>
    );
};

export default BookingLink;
