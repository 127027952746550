// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s-module__wrap--Ozbxd {
  border-radius: 10px;
  border: 1px solid var(--gray);
  padding: 24px;
}
.s-module__wrap--Ozbxd .s-module__header--jlm__ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.s-module__wrap--Ozbxd .s-module__header--jlm__ .s-module__title--h6URh {
  color: var(--gray-700, #374151);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 100% */
}
.s-module__wrap--Ozbxd .s-module__item--gCopf {
  margin-bottom: 16px;
}
.s-module__wrap--Ozbxd .s-module__item--gCopf .s-module__itemTitle--pThDq {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0%;
  color: #6B7280;
}
.s-module__wrap--Ozbxd .s-module__item--gCopf .s-module__itemValue--ZTvWI {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.s-module__wrap--Ozbxd .s-module__item--gCopf .s-module__itemValue--ZTvWI .s-module__tag--St26o {
  padding: 2px 10px;
  background-color: #F3F4F6;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: center;
  color: var(--gray-700);
}
`, "",{"version":3,"sources":["webpack://./src/pages/operation/CustomizeHelloKlarityPPP/components/MedicationsPrescribed/s.module.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,6BAAA;EACA,aAAA;AACJ;AAJA;EAMQ,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACR;AAVA;EAYY,+BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACV,SAAS;AACX;AAlBA;EAuBQ,mBAAA;AAFR;AArBA;EAyBY,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;AADZ;AA7BA;EAkCY,aAAA;EACA,eAAA;EACA,QAAA;AAFZ;AAlCA;EAuCgB,iBAAA;EACA,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,sBAAA;AAFhB","sourcesContent":[".wrap {\n    border-radius: 10px;\n    border: 1px solid var(--gray);\n    padding: 24px;\n\n    .header {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        margin-bottom: 20px;\n\n        .title {\n            color: var(--gray-700, #374151);\n            font-size: 20px;\n            font-style: normal;\n            font-weight: 500;\n            line-height: 20px; /* 100% */\n        }\n        \n        \n    }\n\n    .item {\n        margin-bottom: 16px;\n        .itemTitle {\n            margin-bottom: 4px;\n            font-weight: 500;\n            font-size: 14px;\n            line-height: 20px;\n            letter-spacing: 0%;\n            color: #6B7280;\n        }\n\n        .itemValue {\n            display: flex;\n            flex-wrap: wrap;\n            gap: 8px;\n\n            .tag {\n                padding: 2px 10px;\n                background-color: #F3F4F6;\n                font-weight: 500;\n                font-size: 14px;\n                line-height: 20px;\n                letter-spacing: 0%;\n                text-align: center;\n                color: var(--gray-700);\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `s-module__wrap--Ozbxd`,
	"header": `s-module__header--jlm__`,
	"title": `s-module__title--h6URh`,
	"item": `s-module__item--gCopf`,
	"itemTitle": `s-module__itemTitle--pThDq`,
	"itemValue": `s-module__itemValue--ZTvWI`,
	"tag": `s-module__tag--St26o`
};
export default ___CSS_LOADER_EXPORT___;
