import React, { useRef, useState, useEffect, useMemo } from 'react';
import { message, Spin, Tour } from 'antd';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
import useHomeConfig from 'hooks/useHomeConfig';
import PaymentStatus from 'components/PaymentStatus';
import AgreementStatus from '../AgreementStatus';
import SubscriptionStore from 'store/Subscription';
import { isFreeUserByInfo } from 'utils/provider';
import { CouponType, Provider } from 'types/provider';
import type { TProfileData } from 'types/home';
import Summary from '../Summary';
import TopOpportunities from '../TopOpportunities';
import GrowYourBusiness from '../GrowYourBusiness';
import QuickActions from '../QuickActions';
import ProviderStore from 'store/Provider';
import type { TourProps } from 'antd';
import { ETimeType, getQuickAction } from 'api/home';
import TopOpportunitiesGuide from '../TopOpportunitiesGuide';
import s from './s.module.less';
import CareCommitmentCards from 'components/CareCommitmentCards';
import ProviderCampainHeader from 'components/ProviderCampainHeader';
import useGetCampaignList from 'hooks/Campaign/useGetCampaignList';
import CampaignStore from 'store/Campaign';
import useGetCCCList from 'hooks/ccc/useGetCCCList';
import CCCStore from 'store/CCC';
import { ICCCDashboardItem } from 'types/ccc';
import useGetProviderReferralInfo from 'hooks/referral/useGetProviderReferralInfo';
import KiwiReferralBanner from 'components/ProviderCampainHeader/campains/KiwiReferralBanner';
import useGetTelehealthVisitAndPrescriptionRefill from 'hooks/Campaign/useGetTelehealthVisitAndPrescriptionRefill';
import { ICampaign } from 'types/campaign';
import { EWebinarScheduleType } from 'types/common';
import WebinarBooking from 'components/WebinarBooking';

type TProps = {
    showWebinarPop: boolean;
    showWebinarBanner: boolean;
    webinarLink: string;
    webinarTitle: string;
    webinarDes: string;
    webinarScheduleType?: EWebinarScheduleType;
    onCloseWebinarPop: (booked: boolean) => void,
    onScheduled: () => void;
};

const GrowthHub = (props: TProps) => {
    const { showWebinarPop, webinarLink, webinarTitle, webinarDes, webinarScheduleType, showWebinarBanner, onCloseWebinarPop, onScheduled } = props;
    const [data, loadingHome, fetchData] = useProviderHomeInfo();
    const [get] = ProviderStore.useStore();
    const provider: Provider = get('data') as Provider;
    const [configList, loadingConfig] = useHomeConfig();
    const [, contextHolder] = message.useMessage();
    const payPlan = SubscriptionStore.get('currentPlan');
    const isFreeUser = payPlan && isFreeUserByInfo(payPlan);
    const agreementReviewTypeList = data?.agreementReviewTypeList;
    const isKlarity = !!data?.klarityUser;
    const [getCampaignStore] = CampaignStore.useStore();
    const [getCCCStore] = CCCStore.useStore();
    const forceRefetchCampaignList = getCampaignStore('forceRefetchCampaignList');
    const forceRefetchCCCList = getCCCStore('forceRefetchCCCList');
    // const [dateRangeFilter, setDateRangeFilter] = useState<string>('LAST_30_DAYS');
    const [cccDashboardData, setCCCDashboardData] = useState<ICCCDashboardItem[]>([]);
    const [campaignList, setCampaignList] = useState<ICampaign[]>([]);

    const spinning = loadingHome || loadingConfig;
    const [profileData, setProfileData] = useState<TProfileData>(
        {} as TProfileData,
    );

    const [campaignListData, loadingCampaign, , refetchCampaignList] = useGetCampaignList();
    const [telehealthVisitAndPrescriptionRefillInfo, loadTelehealthVisitAndPrescriptionRefillInfo] = useGetTelehealthVisitAndPrescriptionRefill();
    const [cccList, loadingCCC, , refetchCCCList] = useGetCCCList();

    // 合并telehealthVisitAndPrescriptionRefillInfo，作为campaignList的一部分
    useEffect(() => {
        const listData = [...(campaignListData || [])];
        if (telehealthVisitAndPrescriptionRefillInfo && telehealthVisitAndPrescriptionRefillInfo.canJoin) {
            // if (telehealthVisitAndPrescriptionRefillInfo) {
            listData.push(telehealthVisitAndPrescriptionRefillInfo);
        }

        setCampaignList([...listData]);
    }, [telehealthVisitAndPrescriptionRefillInfo, campaignListData]);

    const [providerReferralInfo, referalLoading] = useGetProviderReferralInfo();

    useEffect(() => {
        if (forceRefetchCampaignList) {
            refetchCampaignList();
        }
    }, [forceRefetchCampaignList, refetchCampaignList]);

    useEffect(() => {
        if (forceRefetchCCCList) {
            refetchCCCList();
        }
    }, [forceRefetchCCCList, refetchCCCList]);

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);

    const [open, setOpen] = useState<boolean>(false);

    const getGreeting = () => {
        const currentHour = new Date().getHours();
        let greeting = '';
        if (currentHour >= 5 && currentHour < 12) {
            greeting = 'Good morning';
        } else if (currentHour >= 12 && currentHour < 17) {
            greeting = 'Good afternoon';
        } else if (currentHour >= 17 && currentHour < 21) {
            greeting = 'Good evening';
        } else {
            greeting = 'Good night';
        }

        return greeting;
    };

    const steps: TourProps['steps'] = [
        {
            title: ' ',
            description:
                'Track key practice metrics and assess progress. Use top-right filters to analyze trends over specific periods.',
            target: () => ref1.current,
            placement: 'bottom',
        },
        {
            title: ' ',
            placement: 'top',
            description:
                'Your guide to practice growth. Discover strategies to expand your patient base, enhance experience, and diversify services.',
            target: () => ref2.current,
        },
        {
            title: ' ',
            placement: 'left',
            description:
                'Your practice growth roadmap. Track progress on key objectives with helpful tips and resources.',
            target: () => ref3.current,
        },
        {
            title: ' ',
            placement: 'left',
            description:
                'Quick access to frequently used tools and resources, streamlining workflow and saving time.',
            target: () => ref4.current,
        },
        {
            title: ' ',
            placement: 'left',
            description:
                'Share your Google profile to existing patients to earn 5-star rating.',
            target: () => ref4.current,
        },
        {
            title: ' ',
            placement: 'left',
            description:
                'Share your Practice Front to allow patients to find out your full services',
            target: () => ref4.current,
        },
    ];

    const getData = async () => {
        const result = await getQuickAction();
        setProfileData(result.data.data);
        if (!localStorage.getItem('HOME_TOUR_ISOPEN')) {
            localStorage.setItem('HOME_TOUR_ISOPEN', 'true');
            setOpen(true);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const handleSetCCCDashboardData = (value: ICCCDashboardItem[]) => {
        setCCCDashboardData(value);
    };

    const campaignBannerRender = useMemo(() => {
        if (isKlarity && campaignList && campaignList.length > 0) {
            return <ProviderCampainHeader campains={campaignList} />;
        }
        if (!isKlarity && providerReferralInfo) {
            return <KiwiReferralBanner referralInfo={providerReferralInfo} />;
        }
    }, [campaignList, isKlarity, providerReferralInfo]);

    return (
        <Spin tip="Loading" size="large" spinning={spinning || loadingCampaign || loadingCCC || referalLoading || loadTelehealthVisitAndPrescriptionRefillInfo}>
            <div className={s.wrapper}>
                <div className={s.inner}>
                    {contextHolder}
                    <div className={s.header}>
                        <div className={s.title}>
                            {getGreeting()}
                            {provider?.firstName ? `, ${provider?.firstName}` : ''} 👋🏻
                        </div>
                    </div>
                    <PaymentStatus />
                    {payPlan?.couponType !== CouponType.HEADWAY &&
                        agreementReviewTypeList &&
                        agreementReviewTypeList.length > 0 && (
                        <AgreementStatus fetchData={fetchData} />
                    )}
                    {campaignBannerRender}
                    <div className={s.content}>
                        <div className={s.left}>
                            <div style={{ width: '100%' }} ref={ref1}>
                                <Summary
                                    showWebinarBanner={showWebinarBanner}
                                    webinarTitle={webinarTitle}
                                    webinarDes={webinarDes}
                                    webinarLink={webinarLink}
                                    webinarScheduleType={webinarScheduleType}
                                    onScheduled={onScheduled}
                                    isKlarity={isKlarity}
                                    profileData={profileData}
                                    setCCCDashboardData={handleSetCCCDashboardData}
                                />
                            </div>
                            {isKlarity && cccList && cccList.length > 0 && <CareCommitmentCards cccList={cccList} cccDashboardData={cccDashboardData} />}
                            {open ? (
                                <div ref={ref2} className={s.positionStep2}>
                                    <TopOpportunitiesGuide isKlarity={isKlarity} campaignList={campaignListData} />
                                </div>
                            ) : (
                                <TopOpportunities isKlarity={isKlarity} campaignList={campaignListData} />
                            )}
                        </div>
                        <div className={s.right}>
                            <div ref={ref3}>
                                <GrowYourBusiness isKlarity={isKlarity} />
                            </div>
                            <div ref={ref4}>
                                <QuickActions
                                    profileData={profileData}
                                    isKlarity={isKlarity}
                                    homeInfo={data}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Tour
                open={open}
                onClose={() => setOpen(false)}
                steps={steps}
                indicatorsRender={(current, total) => (
                    <span>
                        Step {current + 1} out of {total}
                    </span>
                )}
            />
            <WebinarBooking
                modalHeader={(
                    <div className={s.wHeader}>
                        <div className={s.wTitle}>
                            <span className={s.wHigh}>!!</span>
                            Don’t miss out:
                            {webinarTitle?.replace('Schedule', 'schedule')}
                        </div>
                        <div className={s.wDes}>{webinarDes}</div>
                    </div>
                )}
                show={showWebinarPop}
                link={webinarLink}
                webinarScheduleType={webinarScheduleType}
                onClose={onCloseWebinarPop}
            />
        </Spin>
    );
};

export default GrowthHub;
