import React from 'react';
import SurveyIntroPic from 'assets/survey/surveyIntroPic.svg';
import s from './s.module.less';

interface IProps {
    hideIndex: boolean;
}

const SurveyIntro = ({
    hideIndex,
}: IProps) => {
    return (
        <div className={s.introWrap}>
            <div className={s.left}>
                { !hideIndex && <div className={s.stepText}>Step 1</div> }
                <div className={s.title}>Review the patient’s survey journey</div>
                <div className={s.desc}>See what patients will receive, including the survey invitation email and survey questions. This helps you understand their journey and ensure everything looks as expected.</div>
                {/* <div className={s.tip}>
                    <div className={s.tipIcon} />
                    <div className={s.tipContent}>The number of surveys you can send each week depends on your current account level, but upgrading for additional capacity is always an option.</div>
                </div> */}
            </div>
            <div className={s.right}>
                <img src={SurveyIntroPic} />
            </div>
        </div>
    );
};

export default SurveyIntro;
