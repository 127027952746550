import React, { useState } from 'react';
import { Modal, Radio, RadioChangeEvent, message } from 'antd';
import s from './s.module.less';
import commonS from 'styles/common.module.less';
import { saveReviewTrigger } from 'api/survey';

type Props = {
    show:boolean,
    onCannel?: ()=>void,
};

const SettingsOverviewModal = (props: Props) => {
    const { show, onCannel } = props;
    const [value, setValue] = useState<number>(4);
    const [loading, setLoading] = useState<boolean>(false);

    const onChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };

    const onOk = async () => {
        try {
            setLoading(true);
            const res = await saveReviewTrigger({ minimalSurveyScore: value });
            if (res && !res.error) {
                message.success('Save successfully');
                onCannel?.();
            } else {
                message.error(res?.error || 'Save failed');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="Settings"
            closable
            className={commonS.modalFixHeightWrap}
            width="650px"
            onCancel={() => {
                onCannel?.();
            }}
            okText="Save"
            open={show}
            destroyOnClose
            onOk={onOk}
            confirmLoading={loading}
        >
            <div className={s.wrap}>
                <div className={s.title}>Please select who you want to invite to write reviews on your public channels:</div>
                <Radio.Group
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 8,
                    }}
                    onChange={onChange}
                    value={value}
                    options={[
                        { label: 'High satisfaction patients (4 stars or above)', value: 4 },
                        { label: 'Outstanding satisfaction patients (5 stars only)', value: 5 },
                    ]}
                />
            </div>
        </Modal>
    );
};

export default SettingsOverviewModal;
