import React, { useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { EditModuleEnum } from 'types/practiceFront';
import { Select, Spin, Tabs } from 'antd';
import { UniprofileFormData } from 'types/provider';
import type { TabsProps } from 'antd';
import SpecificAbout from '../SpecificAbout';
import SepecificSpecialties from '../SepecificSpecialties';
import useGetSpecificConditionDetail from 'hooks/helloklarityOps/useGetSpecificConditionDetail';
import useGetSpecificConditionListByProviderId from 'hooks/helloklarityOps/useGetSpecificConditionListByProviderId';
import { IDraftStorage, IGetSpecificConditionDetailResponse } from 'types/helloKlarity';
import { getDraftData } from 'utils/helloklarity';

interface IProps {
    id: number;
    providerId?: number;
    setSpecialtiesIsEdting?: (status: boolean) => void;
    providerData: UniprofileFormData;
    onSubmit?: (formValue, field: EditModuleEnum, callback?: () => void) => void;
    refetch: () => void;
    onTabChange: (key: string) => void;
    onSaveSuccess?: (value: string[]) => void;
    conditionId: string | undefined;
    setConditionId: (conditionId: string | undefined) => void;
    specificConditionDetail?: IGetSpecificConditionDetailResponse;
    fetchSpecificConditionDetail: (params: { providerId: number, condition: string }) => void;
    bookingLinkListManualEdited?: boolean;
}

const UpdateSpecificUniprofile = ({
    bookingLinkListManualEdited,
    id,
    providerId,
    setSpecialtiesIsEdting,
    providerData,
    onSubmit,
    refetch,
    onTabChange,
    onSaveSuccess,
    conditionId,
    setConditionId,
    specificConditionDetail,
    fetchSpecificConditionDetail,
}: IProps) => {
    const [tabItems, setTabItems] = useState<TabsProps['items']>([]);
    const [specificConditionListFromApi, specificConditionListFromApiLoading] = useGetSpecificConditionListByProviderId(providerId);
    const [specificConditionList, setSpecificConditionList] = useState<string[]>([]);

    // 如果specificConditionList为空或者手动修改了bookingLink暂未保存，那么从draft的bookings中conditions字段获取
    // conditions需要去重,用 Set 去重
    useEffect(() => {
        if (!providerId) {
            setSpecificConditionList(specificConditionListFromApi);
        } else {
            const draftData: IDraftStorage = getDraftData(providerId);
            const { bookingListData: draftBookingListData } = draftData || {};
            if (draftBookingListData && draftBookingListData.length > 0) {
                const conditions = draftBookingListData.map((e) => e.conditions)
                    .filter((e) => !!e)
                    .flat();
                setSpecificConditionList(Array.from(new Set(conditions)));
            } else {
                setSpecificConditionList(specificConditionListFromApi);
            }
        }
    }, [bookingLinkListManualEdited, providerId, specificConditionListFromApi]);

    useEffect(() => {
        if (conditionId && providerId) {
            fetchSpecificConditionDetail({ providerId, condition: conditionId });
        }
    }, [conditionId, providerId]);

    useEffect(() => {
        if (!conditionId) {
            setConditionId(specificConditionList?.[0]);
        }
    }, [specificConditionList, conditionId, setConditionId]);

    const handleTabClick = useCallback(() => {}, []);

    const handleSubmit = useCallback((formValue: any, field: EditModuleEnum, callback?: () => void) => {
        const result = {
            condition: conditionId,
        };
        if (field === EditModuleEnum.SPECIFIC_ABOUT) {
            result.aboutMe = formValue.aboutMe;
            result.headLines = formValue.headLines;
        }

        if (field === EditModuleEnum.SPECIFIC_TAGS) {
            result.tags = formValue;
        }

        if (field === EditModuleEnum.SPECIFIC_MEDICATIONS_PRESCRIBED) {
            result.prescribableMedications = formValue.prescribableMedications;
            result.nonPrescribableMedications = formValue.nonPrescribableMedications;
        }

        if (field === EditModuleEnum.SPECIFIC_SPECIALTIES) {
            result.specialty = formValue.specialty;
            result.conditionTreated = formValue.conditionTreated;
            result.specialtyHighlight = formValue.specialtyHighlight;
        }

        onSubmit?.(result, EditModuleEnum.SPECIFIC_UNIPROFILE, callback);
    }, [conditionId, onSubmit]);

    useEffect(() => {
        const tabs = [
            {
                key: 'About me',
                label: 'About',
                children: <SpecificAbout
                    conditionId={conditionId}
                    providerData={providerData}
                    specificConditionDetail={specificConditionDetail}
                    onSubmit={handleSubmit}
                    refetch={refetch}
                />,
            },
        ];

        if (providerData?.practice?.specialtyList?.length > 0 || providerData?.practice?.conditionTreatedList?.length > 0) {
            tabs.push({
                key: 'Specialties',
                label: 'Specialties',
                children: <SepecificSpecialties
                    specificConditionDetail={specificConditionDetail}
                    id={id}
                    providerId={providerData.id}
                    providerData={providerData}
                    onSubmit={handleSubmit}
                />,
            });
        }

        setTabItems(tabs);
    }, [conditionId, handleSubmit, id, onSaveSuccess, onSubmit, providerData, refetch, setSpecialtiesIsEdting, specificConditionDetail, specificConditionList]);

    return (
        <div className={s.wrap}>
            <Spin spinning={!!(specificConditionListFromApiLoading)}>
                <Select
                    options={
                        specificConditionList?.map((e) => ({ label: e, value: e }))
                    }
                    value={conditionId}
                    style={{ width: '100%', marginTop: 16 }}
                    onChange={(v) => {
                        setConditionId(v);

                        if (providerId) {
                            fetchSpecificConditionDetail({ providerId, condition: v });
                        }
                    }}
                />
                {
                    conditionId && (
                        <Tabs
                            tabBarStyle={{ position: 'sticky', top: 0, backgroundColor: '#F9FAFB', zIndex: 2, marginBottom: '24px' }}
                            onTabClick={handleTabClick}
                            onChange={(key: string) => {
                                onTabChange(key);
                            }}
                            defaultActiveKey="About me"
                            // activeKey={tabKey}
                            items={tabItems}
                            centered
                        />
                    )
                }
            </Spin>
        </div>
    );
};

export default UpdateSpecificUniprofile;
